<script setup lang="ts">
import { onMounted } from "vue";

import { useI18n } from "vue-i18n";

import HomeView from "../views/home-view/HomeView.vue";

const { t, locale } = useI18n();

onMounted(() => {
  // Dynamic Metadata
  document.title = t("seo.title");
  const metaDescription = document.createElement("meta");
  metaDescription.name = "description";
  metaDescription.content = t("seo.description");
  document.head.appendChild(metaDescription);

  const metaKeywords = document.createElement("meta");
  metaKeywords.name = "keywords";
  metaKeywords.content = t("seo.keywords");
  document.head.appendChild(metaKeywords);

  // Hreflang Tags
  const hreflangLinks = [
    { lang: "en", url: "https://www.resumify-ai.com/" },
    { lang: "de", url: "https://www.resumify-ai.com/de/" },
    { lang: "tr", url: "https://www.resumify-ai.com/tr/" },
  ];
  hreflangLinks.forEach(({ lang, url }) => {
    const link = document.createElement("link");
    link.rel = "alternate";
    link.hreflang = lang;
    link.href = url;
    document.head.appendChild(link);
  });

  const structuredData = [
    {
      "@context": "https://schema.org",
      "@type": "WebSite",
      "url": `https://www.resumify-ai.com/${locale.value}/`,
      "name": t("seo.title"),
      "potentialAction": {
        "@type": "SearchAction",
        "target": `https://www.resumify-ai.com/${locale.value}/?s={search_term_string}`,
        "query-input": "required name=search_term_string",
      },
    },
    {
      "@context": "https://schema.org",
      "@type": "Organization",
      "name": t("seo.organizationName"),
      "url": `https://www.resumify-ai.com/${locale.value}/`,
      "sameAs": [
        "https://instagram.com/resumify_ai",
        "https://www.linkedin.com/company/b-ai-solutions-ug/",
      ],
    },
    {
      "@context": "https://schema.org",
      "@type": "Product",
      "name": t("seo.productName"),
      "description": t("seo.productDescription"),
      "brand": t("seo.productBrand"),
      "offers": [
        {
          "@type": "Offer",
          "priceCurrency": t("seo.productCurrency"),
          "price":  t("seo.productPriceFourMonths"),
          "availability": "https://schema.org/InStock",
          "url": `https://www.resumify-ai.com/${locale.value}/pricing`,
        },
        {
          "@type": "Offer",
          "priceCurrency": t("seo.productCurrency"),
          "price": t("seo.productPriceFourMonths"),
          "availability": "https://schema.org/InStock",
          "url": `https://www.resumify-ai.com/${locale.value}/pricing`,
        },
      ],
    },
    {
      "@context": "https://schema.org",
      "@type": "BreadcrumbList",
      "itemListElement": [
        {
          "@type": "ListItem",
          "position": 1,
          "name": t("breadcrumbs.Home"),
          "item": `https://www.resumify-ai.com/${locale.value}/`,
        },
        {
          "@type": "ListItem",
          "position": 2,
          "name": t("breadcrumbs.CvCreation"),
          "item": `https://www.resumify-ai.com/${locale.value}/cv-creation`,
        },
        {
          "@type": "ListItem",
          "position": 3,
          "name": t("breadcrumbs.CvEvaluation"),
          "item": `https://www.resumify-ai.com/${locale.value}/cv-evaluation`,
        },
        {
          "@type": "ListItem",
          "position": 4,
          "name": t("breadcrumbs.ImportCV"),
          "item": `https://www.resumify-ai.com/${locale.value}/cv-existing`,
        },
        {
          "@type": "ListItem",
          "position": 5,
          "name": t("breadcrumbs.Library"),
          "item": `https://www.resumify-ai.com/${locale.value}/library`,
        },
        {
          "@type": "ListItem",
          "position": 6,
          "name": t("breadcrumbs.Faq"),
          "item": `https://www.resumify-ai.com/${locale.value}/faq`,
        },
      ],
    },
  ];

  const structuredDataScript = document.createElement("script");
  structuredDataScript.type = "application/ld+json";
  structuredDataScript.textContent = JSON.stringify(structuredData);
  document.head.appendChild(structuredDataScript);

  const faqStructuredData = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": [
      {
        "@type": "Question",
        "name": t("faqs.serviceOffered"),
        "acceptedAnswer": {
          "@type": "Answer",
          "text": t("faqs.serviceOfferedAnswer"),
        },
      },
      {
        "@type": "Question",
        "name": t("faqs.howToUpgrade"),
        "acceptedAnswer": {
          "@type": "Answer",
          "text": t("faqs.howToUpgradeAnswer"),
        },
      },
      {
        "@type": "Question",
        "name": t("faqs.paymentMethods"),
        "acceptedAnswer": {
          "@type": "Answer",
          "text": t("faqs.paymentMethodsAnswer"),
        },
      },
      {
        "@type": "Question",
        "name": t("faqs.cancelSubscription"),
        "acceptedAnswer": {
          "@type": "Answer",
          "text": t("faqs.cancelSubscriptionAnswer"),
        },
      },
      {
        "@type": "Question",
        "name": t("faqs.premiumTemplates"),
        "acceptedAnswer": {
          "@type": "Answer",
          "text": t("faqs.premiumTemplatesAnswer"),
        },
      },
      {
        "@type": "Question",
        "name": t("faqs.accountToUse"),
        "acceptedAnswer": {
          "@type": "Answer",
          "text": t("faqs.accountToUseAnswer"),
        },
      },
      {
        "@type": "Question",
        "name": t("faqs.techSupport"),
        "acceptedAnswer": {
          "@type": "Answer",
          "text": t("faqs.techSupportAnswer"),
        },
      },
      {
        "@type": "Question",
        "name": t("faqs.useMultipleDevices"),
        "acceptedAnswer": {
          "@type": "Answer",
          "text": t("faqs.useMultipleDevicesAnswer"),
        },
      },
      {
        "@type": "Question",
        "name": t("faqs.dataPrivacy"),
        "acceptedAnswer": {
          "@type": "Answer",
          "text": t("faqs.dataPrivacyAnswer"),
        },
      },
      {
        "@type": "Question",
        "name": t("faqs.editAfterCreate"),
        "acceptedAnswer": {
          "@type": "Answer",
          "text": t("faqs.editAfterCreateAnswer"),
        },
      },
      {
        "@type": "Question",
        "name": t("faqs.downloadResume"),
        "acceptedAnswer": {
          "@type": "Answer",
          "text": t("faqs.downloadResumeAnswer"),
        },
      },
    ],
  };

  const script = document.createElement("script");
  script.type = "application/ld+json";
  script.textContent = JSON.stringify(faqStructuredData);
  document.head.appendChild(script);
});
</script>

<template>
  <HomeView />
</template>
