<script setup lang="ts">

import { useI18n } from "vue-i18n";

import type { SectionScore } from "@/composables/types";
import { useEvaluationUtils } from "@/utils/getEvaluationScore";
import { getActualScore } from "@/utils/scoreAdjustments";

const { getScoreColor, getScoreRange, scoreOutOfHundred } = useEvaluationUtils();
const { locale } = useI18n();

defineProps<{
    sectionScores: Array<SectionScore>;
  }>();

const scoreToDashArray = (score: string): string => {
  const percentage = parseInt(score);

  const circumference = 283;
  const filledLength = (percentage / 100) * circumference;
  return `${filledLength} ${circumference}`;
};

</script>

<template>
  <div :class="`cv-evaluation-section-scores ${sectionScores.length < 3 ? 'center-items' : ''}`">
    <div v-for="(section, index) in sectionScores" :key="index" class="score-card">
      <div class="section-name">{{ section.sectionName }}</div>
      <svg class="score-circle" viewBox="0 0 100 100">
        <circle class="circle-background" cx="50" cy="50" r="45"></circle>
        <circle
          class="circle-score"
          :stroke="getScoreColor(scoreOutOfHundred(section) ? section?.score || '' : getActualScore(Number(section?.score), section?.sectionName, locale) || '')"
          cx="50"
          cy="50"
          r="45"
          :stroke-dasharray="scoreToDashArray(scoreOutOfHundred(section) ? section?.score || '' : getActualScore(Number(section?.score), section?.sectionName, locale) || '')"
          transform="rotate(-90 50 50)"
        ></circle>
        <text class="score-text" x="50" y="55" dominant-baseline="middle" text-anchor="middle">
          {{ scoreOutOfHundred(section) ? section?.score || '' : getActualScore(Number(section?.score), section?.sectionName, locale) || '' }}%
        </text>
      </svg>
      <div class="score-range">{{ getScoreRange(scoreOutOfHundred(section) ? section?.score || '' : getActualScore(Number(section?.score), section?.sectionName, locale) || '') }}</div>
    </div>
  </div>
</template>

<style scoped>
.cv-evaluation-section-scores {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-bottom: 50px;
}

.cv-evaluation-section-scores.center-items {
  justify-content: center;
}

.score-card {
  width: calc(33.33% - 20px);
  text-align: center;
  margin: 20px;
}

.cv-evaluation-section-scores.center-items .score-card {
  width: calc(50% - 20px);
}

  .score-circle {
    width: 100px;
    height: 100px;
  }

  .circle-background {
    fill: none;
    stroke: #f2f2f2;
    stroke-width: 10;
  }

  .circle-score {
  fill: none;
  stroke-width: 10;
  stroke-linecap: round;
  transition: none;
}

  .score-text {
    font-size: 24px;
    fill: #333;
  }

  .score-range {
    margin-top: 10px;
  }

  .score-range.Poor {
    color: #f21d0d;
  }

  .score-range.Fair {
    color: #ff4f17;
  }

  .score-range.Average {
    color: #ffc107;
  }

  .score-range.Good {
    color: #67bd6a;
  }

  .score-range.Excellent {
    color: #429846;
  }
  .cv-evaluation-section-scores.center-items {
  justify-content: center;
}
.section-name {
  height: 45px;;
  font-weight: bold;
  margin-bottom: 5px;
  text-align: center;
}

  </style>
