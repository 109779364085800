<script setup lang="ts">
import { onMounted } from "vue";

import { useI18n } from "vue-i18n";

import CvEvaluationView from "../views/cv-evaluation-view/CvEvaluationView.vue";

const { t, locale } = useI18n();

onMounted(() => {
  // Meta Tags
  document.title = t("cvEvaluation.metaTitle");

  const metaDescription = document.createElement("meta");
  metaDescription.name = "description";
  metaDescription.content = t("cvEvaluation.metaDescription");
  document.head.appendChild(metaDescription);

  const metaKeywords = document.createElement("meta");
  metaKeywords.name = "keywords";
  metaKeywords.content = t("cvEvaluation.metaKeywords");
  document.head.appendChild(metaKeywords);

  // Determine the URL based on locale
  const baseUrl = "https://www.resumify-ai.com";
  const pagePath = locale.value === "en" ? "/cv-evaluation" : `/${locale.value}/cv-evaluation`;
  const fullUrl = `${baseUrl}${pagePath}`;

  // JSON-LD Structured Data
  const structuredData = {
    "@context": "https://schema.org",
    "@type": "WebPage",
    "name": t("cvEvaluation.metaTitle"),
    "description": t("cvEvaluation.metaDescription"),
    "url": fullUrl,
    "about": t("cvEvaluation.about"),
    "inLanguage": locale.value,
    "isPartOf": {
      "@type": "WebSite",
      "url": baseUrl,
    },
  };

  const script = document.createElement("script");
  script.type = "application/ld+json";
  script.textContent = JSON.stringify(structuredData);
  document.head.appendChild(script);
});
</script>

<template>
  <CvEvaluationView />
</template>
