<script setup lang="ts">

import type { Ref } from "vue";
import { computed, ref , watch as vueWatch } from "vue";

import type { AxiosResponse } from "axios";
import axios from "axios";
import { useI18n } from "vue-i18n";

import { useAuth } from "@/auth/useAuth";
import AuthModal from "@/components/AuthModal.vue";
//import UpgradePremium from "@/components/UpgradePremium.vue";
import { API_URL } from "@/config";
import { processFile } from "@/utils/file";
import { DEFAULT_SCORE, DEFAULT_EVALUATION_TEXT, JOB_TITLES_EN, JOB_TITLES_DE, JOB_TITLES_TR } from "@/views/cv-evaluation-view/const";
import LoadingPage from "@/views/cv-evaluation-view/cv-evaluation/cv-evaluation-loading/LoadingPage.vue";
import CvEvaluationScore from "@/views/cv-evaluation-view/cv-evaluation/cv-evaluation-output-sections/CvEvaluationScore.vue";
import CvEvaluationSectionScores from "@/views/cv-evaluation-view/cv-evaluation/cv-evaluation-output-sections/CvEvaluationSectionScores.vue";
import CvEvaluationText from "@/views/cv-evaluation-view/cv-evaluation/cv-evaluation-output-sections/CvEvaluationText.vue";

const { user } = useAuth();
const { t, locale } = useI18n();
const invalidFileError = t("InvalidFileType");
const fileTooLargeError = t("FileTooLarge");

const jobTitles = computed(() => {
  switch (locale.value) {
  case "de":
    return JOB_TITLES_DE;
  case "tr":
    return JOB_TITLES_TR;
  default:
    return JOB_TITLES_EN;
  }
});

//const MAX_FILE_SIZE = 6 * 1024 * 1024;
interface FeedbackResponse {
  componentsWithScores: string;
}

const rightPanelRef = ref<HTMLElement | null>(null);

const scrollToRightPanel = () => {
  if (rightPanelRef.value) {
    rightPanelRef.value.scrollIntoView({ behavior: "smooth", block: "start" });
  }
};

const modalAuthenticationVisible = ref(false);
const showLoading = ref(false);
const showEvaluation = ref(false);
const selectedJobTitle = ref("");
const showResults = ref(true);
const isEvaluating = ref(false);
const evaluationText = ref(DEFAULT_EVALUATION_TEXT);
const sectionScores = ref([]);
const creditNotEnough = ref(false);
const score = ref(DEFAULT_SCORE);
const fetchWaiting = ref(false);

const pdfBlobUrl = ref("");
const pdfFile: Ref<File | null> = ref(null);
const pdfData = ref("");

const emit = defineEmits(["scrollToTarget"]);

const fetchFeedback = async (text: string) => {

  try {
    const csrfResponse = await axios.get(API_URL +  "/csrf-token", { withCredentials: true });
    const csrfToken = csrfResponse.data.csrfToken;

    const response: AxiosResponse<FeedbackResponse> = await axios({
      method: "POST",
      url: API_URL + "/componentsFeedbackWithScores",
      data: JSON.stringify({ cvText: text, jobTitle: selectedJobTitle, language: t("Locale"), uid: user.value.details?.id || ""  }),
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": csrfToken,
      },
      withCredentials: true,
    });

    if (!response.data) {
      throw new Error("Network response was not ok");
    }

    const data = await response.data;

    const componentsWithScores = JSON.parse(data.componentsWithScores);
    score.value = parseInt(componentsWithScores.generalscore, 10);
    evaluationText.value = componentsWithScores.evaluation;
    sectionScores.value = componentsWithScores.sections;
    showEvaluation.value = true;
    showLoading.value = false;

  }
  catch (error: any) {
    if(error?.response?.status === 402){
      creditNotEnough.value = true;
      emit("scrollToTarget");
    }
  }

};

const startEvaluation = async () => {
  if (pdfFile.value && !isEvaluating.value) {
    isEvaluating.value = true;

    try {
      showLoading.value = true;
      const text: string = await processFile(pdfFile.value, invalidFileError, fileTooLargeError);
      pdfData.value = text;
      scrollToRightPanel();
      await fetchFeedback(pdfData.value);
    } catch (error) {
      console.error("Evaluation failed:", error);
    } finally {
      showLoading.value = false;
      isEvaluating.value = false;
    }
  } else {
    alert("Please upload a CV first.");
  }
};

const openFileSelector = () => {

  document.getElementById("pdfInput")?.click();

};

const resetFileInput = () => {
  const fileInput = document.getElementById("pdfInput") as HTMLInputElement;
  if (fileInput) {
    fileInput.value = "";
  }
};

const resetEvaluation = () => {
  pdfFile.value = null;
  pdfBlobUrl.value = "";
  pdfData.value = "";
  score.value = DEFAULT_SCORE;
  evaluationText.value = DEFAULT_EVALUATION_TEXT;
  sectionScores.value = [];
  showEvaluation.value = false;
  showLoading.value = false;
  creditNotEnough.value = false;
  isEvaluating.value = false;
};

const handleFileChange = (event: Event) => {
  const file = (event.target as HTMLInputElement).files?.[0];
  if (file && file.type === "application/pdf") {
    resetEvaluation();
    pdfFile.value = file;
    pdfBlobUrl.value = URL.createObjectURL(file);
    if(user.value?.authUser){
      startEvaluation();
      fetchWaiting.value  =false;
      resetFileInput();
    }

    else{
      fetchWaiting.value = true;
      modalAuthenticationVisible.value = true;
    }
  } else {
    alert("Please select a valid PDF file.");
  }
};

const filteredJobTitles = computed(() => {
  const query = selectedJobTitle.value.trim().toLowerCase();
  if (!query) {
    return [];
  }
  return jobTitles.value.filter(jobTitle => jobTitle.toLowerCase().includes(query));
});

const selectJobTitle = (jobTitle: string) => {
  selectedJobTitle.value = jobTitle;
  showResults.value = false;
};

const deselectJobTitle = () => {
  selectedJobTitle.value = "";
  showResults.value = true;
};

vueWatch(
  () => user.value.details,
  (newAuthUser) => {
    if (newAuthUser?.id && fetchWaiting.value) {
      startEvaluation();
      resetFileInput();
      fetchWaiting.value  =false;
    }
  },
  { deep: true },
);

</script>

<template>
  <AuthModal :isVisible="modalAuthenticationVisible" @update:visible="modalAuthenticationVisible = $event"/>
  <div class="evaluation-div">
    <span class="text-evaluate">
      {{ t('MobileEvaluateResume') }}
    </span>

    <div ref="rightPanelRef" class="right-panel-mobile">

      <div v-if="showLoading && !showEvaluation" class="loading-message">
        <LoadingPage />
      </div>
      <div v-if="!showLoading && showEvaluation">
        <div v-if="selectedJobTitle" class="selected-job-title">
          <h3>{{ t('cvEvaluation.SelectedJobTitle') }}{{ selectedJobTitle }}</h3>
        </div>
        <CvEvaluationScore :key="score" :score="score" />
        <CvEvaluationText :key="evaluationText" :text="evaluationText" />
        <div style="margin: 20px;">
          <EvaluateToEhance :pdfData="pdfData" />
        </div>
        <CvEvaluationSectionScores :sectionScores="sectionScores" />
      </div>
    </div>

    <input
      id="pdfInput"
      type="file"
      accept=".pdf"
      style="display: none"
      @change="handleFileChange"
    />

    <div class="job-title-mobile-div">

      <div class="search-bar-mobile">
        <input
          v-model="selectedJobTitle"
          type="text"
          :placeholder="t('cvEvaluation.DesiredJobTitle')"
          class="search-input-mobile">
        <button v-if="selectedJobTitle" class="deselect-button-mobile" @click="deselectJobTitle">X</button>
      </div>
      <ul v-if="showResults && filteredJobTitles.length" class="results-list-mobile">
        <li
          v-for="jobTitle in filteredJobTitles"
          :key="jobTitle"
          class="result-item-mobile"
          @click="selectJobTitle(jobTitle)"
        >
          {{ jobTitle }}
        </li>
      </ul>
    </div>

    <button class="evaluate-button-mobile" @click="openFileSelector">
      <span>{{ showEvaluation ? t('EvaluateAnother') : t('Evaluate') }}</span>
    </button>

  </div>
</template>

<style>

.right-panel-header-mobile {
    display: flex;
  color: #5438da;
    font-size: 32px;
    font-family: 'Montserrat';
    font-weight: 500;
    line-height: 42px;
    text-align: start;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
}

.right-panel-mobile {
  position: relative;
  width: 90%;
  flex-direction: column;
  overflow-y: auto;
}

.job-title-mobile-div{
    margin-bottom: 30px;
    margin-top: 30px;
}

.search-bar-mobile {
  display: flex;
  align-items: center;
  width: 100%;
  width: 350px;
}

.results-list-mobile {
  width: 350px;
  background-color: #ffffff;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  max-height: 200px;
  overflow-y: auto;
  margin: 0;
  padding: 0;
  list-style: none;
  z-index: 1000;
  text-align: start;
}

.result-item-mobile {
  padding: 10px;
  cursor: pointer;
  font-family: 'Montserrat';
  font-size: 14px;
  color: #333;
}

.result-item-mobile:hover {
  background-color: #f0f0f0;
}

.result-item-mobile:active {
  background-color: #e0e0e0;
}

.search-input-mobile {
  flex-grow: 1;
  height: 56px;
  padding: 0 44px 0 12px;
  box-sizing: border-box;
  font-size: 14px;
  border: 1px solid #5438da;
  border-radius: 8px;
}

.deselect-button-mobile {
  position: absolute;
  right: 40px;
  background: none;
  border: none;
  color: #909090;
  font-size: 18px;
  cursor: pointer;
  padding: 0;
  line-height: 1;
}

.evaluation-div{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 100px;
    margin-top: 50px;
    padding: 10px;
}

.text-evaluate {
  color: #5438da;
  font-size: 28px;
  font-family: "Montserrat";
  font-weight: 500;
  line-height: 42px;
  text-align: center;
  margin-bottom: 30px;
}

.evaluate-button-mobile{
  cursor: pointer;
  width: 143px;
  height: 48px;
  padding: 0px 8px;
  border: 0;
  border-radius: 8px;
  box-shadow: 0px 0px 10px rgba(0,0,0,0.1);
  background-color: #5438da;
  color: #ffffff;
  font-size: 14px;
  font-family: "Roboto";
  line-height: 24px;
  outline: none;
  margin-left: 0;
  margin-bottom: 70px
}

</style>