import axios from "axios";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import type { RouteLocationNormalizedLoaded, RouteLocation } from "vue-router";
import { createRouter, createWebHistory } from "vue-router";

import { addToHistory } from "@/composables/useNavigationHistory";
import { API_URL } from "@/config";
import AboutUsPage from "@/pages/AboutUsPage.vue";
import CancellationPolicyPage from "@/pages/CancellationPolicyPage.vue";
import ContactUsPage from "@/pages/ContactUsPage.vue";
import CvCreationPage from "@/pages/CvCreationPage.vue";
import CvEvaluationPage from "@/pages/CvEvaluationPage.vue";
import CvExistingPage from "@/pages/CvExistingPage.vue";
import ErrorPage from "@/pages/ErrorPage.vue";
import FaqPage from "@/pages/FaqPage.vue";
import Home from "@/pages/HomePage.vue";
import ImprintPage from "@/pages/ImprintPage.vue";
import InfoPage from "@/pages/InfoPage.vue";
import Library from "@/pages/LibraryPage.vue";
import MyAccount from "@/pages/MyAccountPage.vue";
// import PasswordPage from "@/pages/PasswordPage.vue";
import PaymentPage from "@/pages/PaymentPage.vue";
import PricingPage from "@/pages/PricingPage.vue";
import PrivacyPolicyPage from "@/pages/PrivacyPolicyPage.vue";
import ResetPasswordPage from "@/pages/ResetPasswordPage.vue";
import SmallScreenPage from "@/pages/ScreenSizeSmall.vue";
import TermsAndConditionsPage from "@/pages/TermsAndConditionsPage.vue";
import VerifyAccountPage from "@/pages/VerifyAccountPage.vue";
import { getUserInfo } from "@/services/userService";
import { constructLocalizedPath, constructLangPrefix } from "@/utils/pathAdjuster";
const baseRoutes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      title: "Resume Builder & CV Enhancer | Resumify AI | AI Powered",
      metaTags: [
        {
          name: "description",
          content:
          "Resumify AI uses advanced artificial intelligence to help you create, update, and optimize your resume for better job opportunities.",
        },
        {
          name: "keywords",
          content: "AI Resume Builder, Resume Enhancement, CV Maker, Resumify AI",
        },
        {
          property: "og:title",
          content: "AI-Powered Resume Builder & CV Enhancer | Resumify AI",
        },
        {
          property: "og:description",
          content:
          "Transform your resume with advanced AI tools. Create, update, and optimize your resume for better job opportunities.",
        },
        {
          rel: "canonical",
          href: "https://www.resumify-ai.com",
        },
      ] },
    props: (route: RouteLocationNormalizedLoaded) => ({ sessionExpired: route.params.sessionExpired }),

  },

  // {
  //   path: "/password",
  //   name: "Password",
  //   component: PasswordPage,
  // },
  {
    path: "/small-screen",
    name: "SmallScreen",
    component: SmallScreenPage,
    meta: {
      title: "Mobile | Resumify AI",
      metaTags: [
        {
          name: "description",
          content:
            "www.resumify-ai.com for small screens.",
        },
      ],
    },

  },
  {
    path: "/info",
    name: "Info",
    component: InfoPage,
    meta: {
      title: "Information | Resumify AI",
      metaTags: [
        {
          name: "description",
          content:
            "www.resumif-ai.com to evaluate CVs in mobile.",
        },
        {
          rel: "canonical",
          href: "https://www.resumify-ai.com/info",
        },
      ],
    },
  },
  {
    path: "/account",
    name: "Account",
    component: MyAccount,
    meta: {
      title: "My Account | Resumify AI",
      requiresAuth: true,
      metaTags: [
        {
          name: "description",
          content:
            "Access and manage your Resumify AI account settings, subscriptions, and personal information.",
        },
      ],
    },
  },
  {
    path: "/library",
    name: "Library",
    component: Library,
    meta: {
      title: "My Resumes | Resumify AI",
      requiresAuth: true,
      metaTags: [
        {
          name: "description",
          content:
            "Browse and manage your saved resumes in your personal library.",
        },
        {
          rel: "canonical",
          href: "https://www.resumify-ai.com/library",
        },
      ],
    },
  },
  {
    path: "/verify-account",
    name: "Verify",
    component: VerifyAccountPage,
    meta: {
      title: "Verify Your Account | Resumify AI",
      requiresAuth: true,
      metaTags: [
        {
          name: "description",
          content:
            "Verify your Resumify AI account to unlock all features and benefits.",
        },
      ],
    },
  },
  {
    path: "/reset-password",
    name: "Reset",
    component: ResetPasswordPage,
    meta: {
      title: "Reset Password | Resumify AI",
      metaTags: [
        {
          name: "description",
          content:
            "Reset your Resumify AI account password securely and regain access to your account.",
        },
      ],
    },
  },
  {
    path: "/cv-creation/:cvId",
    name: "CvCreation",
    component: CvCreationPage,
    props: true,
    meta: {
      title: "Create Your Resume | Resumify AI",
      requiresAuth: true,
      requiresCvOwnership: true,
      metaTags: [
        {
          name: "description",
          content:
            "Use Resumify AI's advanced tools to create a professional and impactful resume.",
        },
        {
          rel: "canonical",
          href: "https://www.resumify-ai.com/cv-creation",
        },
      ],
    },
  },
  {
    path: "/cv-existing",
    name: "CvExisting",
    component: CvExistingPage,
    meta: {
      title: "Update Existing Resume | Resumify AI",
      requiresAuth: true,
      metaTags: [
        {
          name: "description",
          content:
            "Enhance and update your existing resume with Resumify AI's intelligent suggestions.",
        },
        {
          rel: "canonical",
          href: "https://www.resumify-ai.com/cv-existing",
        },
      ],
    },
  },
  {
    path: "/cv-evaluation",
    name: "CvEvalutation",
    component: CvEvaluationPage,
    meta: {
      title: "Evaluate Your Resume | Resumify AI",
      requiresAuth: true,
      metaTags: [
        {
          name: "description",
          content:
            "Get a comprehensive evaluation of your resume and receive actionable improvement tips.",
        },
        {
          rel: "canonical",
          href: "https://www.resumify-ai.com/cv-evaluation",
        },
      ],
    },
  },
  {
    path: "/pricing",
    name: "Pricing",
    component: PricingPage,
    meta: {
      title: "Pricing Plans | Resumify AI",
      metaTags: [
        {
          name: "description",
          content:
            "Explore Resumify AI's pricing plans and choose the best option for your career needs.",
        },
        {
          rel: "canonical",
          href: "https://www.resumify-ai.com/pricing",
        },
      ],
    },
  },
  {
    path: "/payment",
    name: "Payment",
    component: PaymentPage,
    meta: {
      title: "Secure Payment | Resumify AI",
      requiresAuth: true,
      metaTags: [
        {
          name: "description",
          content:
            "Complete your payment securely and access premium features with Resumify AI.",
        },
        {
          rel: "canonical",
          href: "https://www.resumify-ai.com/payment",
        },
      ],
    },
  },
  {
    path: "/about",
    name: "About",
    component: AboutUsPage,
    meta: {
      title: "About Us | Resumify AI",
      metaTags: [
        {
          name: "description",
          content:
            "Learn about Resumify AI, our mission, and how we help professionals enhance their resumes.",
        },
        {
          property: "og:title",
          content: "About Us | Resumify AI",
        },
        {
          property: "og:description",
          content:
            "Discover the story behind Resumify AI and our commitment to empowering job seekers.",
        },
        {
          property: "og:url",
          content: "https://www.resumify-ai.com/about",
        },
        {
          property: "og:type",
          content: "website",
        },
      ],
    },
  },
  {
    path: "/contact",
    name: "Contact",
    component: ContactUsPage,
    meta: {
      title: "Contact Us | Resumify AI",
      metaTags: [
        {
          name: "description",
          content:
            "Get in touch with the Resumify AI team for support, inquiries, or feedback.",
        },
        {
          property: "og:title",
          content: "Contact Us | Resumify AI",
        },
        {
          property: "og:description",
          content:
            "We're here to help. Contact Resumify AI for assistance or to share your thoughts.",
        },
        {
          property: "og:url",
          content: "https://www.resumify-ai.com/contact",
        },
        {
          property: "og:type",
          content: "website",
        },
      ],
    },
  },
  {
    path: "/faq",
    name: "Faq",
    component: FaqPage,
    meta: {
      title: "Frequently Asked Questions | Resumify AI",
      metaTags: [
        {
          name: "description",
          content:
            "Find answers to common questions about Resumify AI and our services.",
        },
        {
          property: "og:title",
          content: "Frequently Asked Questions | Resumify AI",
        },
        {
          property: "og:description",
          content:
            "Have questions? Check out our FAQ section for quick answers.",
        },
        {
          property: "og:url",
          content: "https://www.resumify-ai.com/faq",
        },
        {
          property: "og:type",
          content: "website",
        },
      ],
    },
  },
  {
    path: "/policies/imprint",
    name: "Imprint",
    component: ImprintPage,
    meta: {
      title: "Imprint | Resumify AI",
      metaTags: [
        {
          name: "description",
          content:
            "Legal information and company details for Resumify AI.",
        },
      ],
    },
  },
  {
    path: "/policies/terms-and-conditions",
    name: "Terms-and-conditions",
    component: TermsAndConditionsPage,
    meta: {
      title: "Terms and Conditions | Resumify AI",
      metaTags: [
        {
          name: "description",
          content:
            "Read the terms and conditions for using Resumify AI's services.",
        },
      ],
    },
  },
  {
    path: "/policies/privacy-policy",
    name: "Privacy-policy",
    component: PrivacyPolicyPage,
    meta: {
      title: "Privacy Policy | Resumify AI",
      metaTags: [
        {
          name: "description",
          content:
            "Learn how Resumify AI collects, uses, and protects your personal information.",
        },
      ],
    },
  },
  {
    path: "/policies/cancellation-policy",
    name: "Cancellation-policy",
    component: CancellationPolicyPage,
    meta: {
      title: "Cancellation Policy | Resumify AI",
      metaTags: [
        {
          name: "description",
          content:
            "Understand our cancellation policy and how to cancel your Resumify AI subscription.",
        },
      ],
    },
  },
  {
    path: "/:catchAll(.*)",
    redirect: (to: RouteLocation): string => {
      const locale = to.fullPath.split("/")[1]; // Extract the first segment of the URL
      const supportedLocales: string[] = ["en", "tr", "de"];

      // Default to English if no valid locale is found
      const langPrefix: string = supportedLocales.includes(locale) ? `/${locale}` : "/en";

      return `${langPrefix}/error`;
    },
  },
  {
    path: "/error",
    name: "Error",
    component: ErrorPage,
    meta: {
      title: "Page Not Found | Resumify AI",
      metaTags: [
        {
          name: "description",
          content:
            "The page you're looking for doesn't exist. Return to the homepage.",
        },
      ],
    },
  },
];

const translations: Record<string, Record<string, { title: string; description: string ; metaTags: Record<string, string>[] }>> = {
  tr: {
    "/": {
      title: "Ana Sayfa | Resumify AI",
      description: "AI teknolojisiyle özgeçmişinizi geliştirin ve iş fırsatlarınızı artırın. En iyi CV oluşturma platformu.",
      metaTags: [
        { name: "description", content: "AI teknolojisiyle özgeçmişinizi geliştirin ve iş fırsatlarınızı artırın. En iyi CV oluşturma platformu." },
        { name: "keywords", content: "AI Özgeçmiş Oluşturucu, Özgeçmiş Geliştirme, CV Yapıcı, Resumify AI" },
        { property: "og:title", content: "Ana Sayfa | Resumify AI" },
        { property: "og:description", content: "AI teknolojisiyle özgeçmişinizi geliştirin ve iş fırsatlarınızı artırın." },
        { rel: "canonical", href: "https://www.resumify-ai.com/tr" },
      ],
    },
    "/about": {
      title: "Hakkımızda | Resumify AI",
      description: "Resumify AI ile adayların iş bulma şansını nasıl artırdığımızı ve misyonumuzu öğrenin.",
      metaTags: [
        { name: "description", content: "Resumify AI ile adayların iş bulma şansını nasıl artırdığımızı ve misyonumuzu öğrenin." },
        { name: "keywords", content: "Hakkımızda, AI Özgeçmiş Oluşturucu, Misyonumuz, Resumify AI" },
        { property: "og:title", content: "Hakkımızda | Resumify AI" },
        { property: "og:description", content: "Resumify AI ile adayların iş bulma şansını artırıyoruz." },
        { rel: "canonical", href: "https://www.resumify-ai.com/tr/about" },
      ],
    },
    "/contact": {
      title: "Bize Ulaşın | Resumify AI",
      description: "Resumify AI ile iletişime geçin. Sorularınız ve destek talepleriniz için buradayız.",
      metaTags: [
        { name: "description", content: "Resumify AI ile iletişime geçin. Sorularınız ve destek talepleriniz için buradayız." },
        { name: "keywords", content: "İletişim, Destek, Resumify AI, Yardım" },
        { property: "og:title", content: "Bize Ulaşın | Resumify AI" },
        { property: "og:description", content: "Sorularınız ve destek talepleriniz için Resumify AI ile iletişime geçin." },
        { rel: "canonical", href: "https://www.resumify-ai.com/tr/contact" },
      ],
    },
    "/faq": {
      title: "Sıkça Sorulan Sorular | Resumify AI",
      description: "Resumify AI hakkında sıkça sorulan soruları ve cevaplarını keşfedin.",
      metaTags: [
        { name: "description", content: "Resumify AI hakkında sıkça sorulan soruları ve cevaplarını keşfedin." },
        { name: "keywords", content: "SSS, Sıkça Sorulan Sorular, Resumify AI" },
        { property: "og:title", content: "Sıkça Sorulan Sorular | Resumify AI" },
        { property: "og:description", content: "Resumify AI hakkında sıkça sorulan sorular ve cevaplar." },
        { rel: "canonical", href: "https://www.resumify-ai.com/tr/faq" },
      ],
    },
    "/pricing": {
      title: "Fiyatlandırma | Resumify AI",
      description: "Resumify AI'nin esnek fiyatlandırma planlarını inceleyin ve size en uygun seçeneği seçin.",
      metaTags: [
        { name: "description", content: "Resumify AI'nin esnek fiyatlandırma planlarını inceleyin ve size en uygun seçeneği seçin." },
        { name: "keywords", content: "Fiyatlandırma, Planlar, Abonelik, Resumify AI" },
        { property: "og:title", content: "Fiyatlandırma | Resumify AI" },
        { property: "og:description", content: "Resumify AI'nin esnek fiyatlandırma planlarını keşfedin." },
        { rel: "canonical", href: "https://www.resumify-ai.com/tr/pricing" },
      ],
    },
    "/library": {
      title: "Özgeçmişlerim | Resumify AI",
      description: "Kaydedilmiş özgeçmişlerinizi kişisel kütüphanenizde düzenleyin ve yönetin.",
      metaTags: [
        { name: "description", content: "Kaydedilmiş özgeçmişlerinizi kişisel kütüphanenizde düzenleyin ve yönetin." },
        { name: "keywords", content: "Kütüphane, Özgeçmişlerim, CV Yönetimi, Resumify AI" },
        { property: "og:title", content: "Özgeçmişlerim | Resumify AI" },
        { property: "og:description", content: "Kaydedilmiş özgeçmişlerinizi yönetin ve düzenleyin." },
        { rel: "canonical", href: "https://www.resumify-ai.com/tr/library" },
      ],
    },
    "/account": {
      title: "Hesabım | Resumify AI",
      description: "Hesap ayarlarınıza, aboneliklerinize ve kişisel bilgilerinize kolayca erişin.",
      metaTags: [
        { name: "description", content: "Hesap ayarlarınıza, aboneliklerinize ve kişisel bilgilerinize kolayca erişin." },
        { name: "keywords", content: "Hesap Yönetimi, Abonelikler, Resumify AI" },
        { property: "og:title", content: "Hesabım | Resumify AI" },
        { property: "og:description", content: "Hesap ayarlarınıza ve bilgilerinize kolay erişim sağlayın." },
        { rel: "canonical", href: "https://www.resumify-ai.com/tr/account" },
      ],
    },
    "/reset-password": {
      title: "Şifre Sıfırla | Resumify AI",
      description: "Şifrenizi güvenli bir şekilde sıfırlayın ve Resumify AI hesabınıza erişin.",
      metaTags: [
        { name: "description", content: "Şifrenizi güvenli bir şekilde sıfırlayın ve Resumify AI hesabınıza erişin." },
        { name: "keywords", content: "Şifre Sıfırla, Hesap Kurtarma, Resumify AI" },
        { property: "og:title", content: "Şifre Sıfırla | Resumify AI" },
        { property: "og:description", content: "Resumify AI şifrenizi kolayca sıfırlayın ve hesabınıza tekrar erişin." },
        { rel: "canonical", href: "https://www.resumify-ai.com/tr/reset-password" },
      ],
    },
    "/cv-creation": {
      title: "CV Oluşturma | Resumify AI",
      description: "Kolay ve profesyonel CV oluşturma için Resumify AI'nin güçlü araçlarını kullanın.",
      metaTags: [
        { name: "description", content: "Kolay ve profesyonel CV oluşturma için Resumify AI'nin güçlü araçlarını kullanın." },
        { name: "keywords", content: "CV Oluşturma, Profesyonel Özgeçmiş, CV Tasarımı, Resumify AI" },
        { property: "og:title", content: "CV Oluşturma | Resumify AI" },
        { property: "og:description", content: "Kolayca profesyonel bir CV oluşturmak için Resumify AI'yi kullanın." },
        { rel: "canonical", href: "https://www.resumify-ai.com/tr/cv-creation" },
      ],
    },
    "/cv-existing": {
      title: "Mevcut CV Güncelle | Resumify AI",
      description: "Mevcut CV'nizi güncelleyin ve optimize ederek iş başvurularında öne çıkın.",
      metaTags: [
        { name: "description", content: "Mevcut CV'nizi güncelleyin ve optimize ederek iş başvurularında öne çıkın." },
        { name: "keywords", content: "CV Güncelle, CV Optimizasyonu, Özgeçmiş Güncelleme, Resumify AI" },
        { property: "og:title", content: "Mevcut CV Güncelle | Resumify AI" },
        { property: "og:description", content: "Mevcut CV'nizi Resumify AI'nin araçlarıyla kolayca güncelleyin." },
        { rel: "canonical", href: "https://www.resumify-ai.com/tr/cv-existing" },
      ],
    },
    "/cv-evaluation": {
      title: "CV Değerlendirme | Resumify AI",
      description: "CV'nizi analiz edin ve gelişmiş iyileştirme önerileri alın.",
      metaTags: [
        { name: "description", content: "CV'nizi analiz edin ve gelişmiş iyileştirme önerileri alın." },
        { name: "keywords", content: "CV Değerlendirme, Özgeçmiş Analizi, CV Geliştirme, Resumify AI" },
        { property: "og:title", content: "CV Değerlendirme | Resumify AI" },
        { property: "og:description", content: "Resumify AI ile CV'nizi analiz edin ve öneriler alın." },
        { rel: "canonical", href: "https://www.resumify-ai.com/tr/cv-evaluation" },
      ],
    },
    "/payment": {
      title: "Ödeme | Resumify AI",
      description: "Ödemenizi güvenli bir şekilde tamamlayın ve premium özelliklere erişin.",
      metaTags: [
        { name: "description", content: "Ödemenizi güvenli bir şekilde tamamlayın ve premium özelliklere erişin." },
        { name: "keywords", content: "Ödeme, Güvenli Ödeme, Premium Özellikler, Resumify AI" },
        { property: "og:title", content: "Ödeme | Resumify AI" },
        { property: "og:description", content: "Güvenli ödeme sistemiyle premium özelliklere kolayca erişin." },
        { rel: "canonical", href: "https://www.resumify-ai.com/tr/payment" },
      ],
    },
    "/policies/imprint": {
      title: "Künye | Resumify AI",
      description: "Resumify AI'nin yasal bilgileri ve şirket detayları hakkında bilgi alın.",
      metaTags: [
        { name: "description", content: "Resumify AI'nin yasal bilgileri ve şirket detayları hakkında bilgi alın." },
        { name: "keywords", content: "Künye, Yasal Bilgiler, Resumify AI" },
        { property: "og:title", content: "Künye | Resumify AI" },
        { property: "og:description", content: "Resumify AI'nin yasal bilgileri hakkında daha fazla bilgi edinin." },
        { rel: "canonical", href: "https://www.resumify-ai.com/tr/policies/imprint" },
      ],
    },
    "/policies/terms-and-conditions": {
      title: "Şartlar ve Koşullar | Resumify AI",
      description: "Resumify AI'nin hizmet şartları ve koşullarını okuyun.",
      metaTags: [
        { name: "description", content: "Resumify AI'nin hizmet şartları ve koşullarını okuyun." },
        { name: "keywords", content: "Şartlar ve Koşullar, Kullanım Koşulları, Resumify AI" },
        { property: "og:title", content: "Şartlar ve Koşullar | Resumify AI" },
        { property: "og:description", content: "Hizmet şartları ve koşullar hakkında bilgi edinin." },
        { rel: "canonical", href: "https://www.resumify-ai.com/tr/policies/terms-and-conditions" },
      ],
    },
    "/policies/privacy-policy": {
      title: "Gizlilik Politikası | Resumify AI",
      description: "Resumify AI'nin kişisel verilerinizi nasıl koruduğunu öğrenin.",
      metaTags: [
        { name: "description", content: "Resumify AI'nin kişisel verilerinizi nasıl koruduğunu öğrenin." },
        { name: "keywords", content: "Gizlilik Politikası, Veri Koruma, Resumify AI" },
        { property: "og:title", content: "Gizlilik Politikası | Resumify AI" },
        { property: "og:description", content: "Resumify AI'nin gizlilik politikası hakkında bilgi edinin." },
        { rel: "canonical", href: "https://www.resumify-ai.com/tr/policies/privacy-policy" },
      ],
    },
    "/policies/cancellation-policy": {
      title: "İptal Politikası | Resumify AI",
      description: "Abonelik iptali ve iptal politikası hakkında bilgi edinin.",
      metaTags: [
        { name: "description", content: "Abonelik iptali ve iptal politikası hakkında bilgi edinin." },
        { name: "keywords", content: "İptal Politikası, Abonelik İptali, Resumify AI" },
        { property: "og:title", content: "İptal Politikası | Resumify AI" },
        { property: "og:description", content: "Resumify AI'nin iptal politikası hakkında daha fazla bilgi edinin." },
        { rel: "canonical", href: "https://www.resumify-ai.com/tr/policies/cancellation-policy" },
      ],
    },
  },
  de: {
    "/": {
      title: "Startseite | Resumify AI",
      description: "Lebenslauf mit Resumify AI erstellen und optimieren. Ihre Karriere starten mit KI-gestützten Tools.",
      metaTags: [
        { name: "description", content: "Lebenslauf mit Resumify AI erstellen und optimieren. Ihre Karriere starten mit KI-gestützten Tools." },
        { name: "keywords", content: "Lebenslauf Builder, CV Optimierung, Karriere Tools, Resumify AI" },
        { property: "og:title", content: "Startseite | Resumify AI" },
        { property: "og:description", content: "Optimieren Sie Ihren Lebenslauf und steigern Sie Ihre Karrierechancen mit KI." },
        { rel: "canonical", href: "https://www.resumify-ai.com/de" },
      ],
    },
    "/about": {
      title: "Über Uns | Resumify AI",
      description: "Erfahren Sie mehr über Resumify AI und wie wir Bewerbern in Deutschland helfen.",
      metaTags: [
        { name: "description", content: "Erfahren Sie mehr über Resumify AI und wie wir Bewerbern in Deutschland helfen." },
        { name: "keywords", content: "Über Uns, Resumify AI, Karriere Förderung, KI Lebenslauf" },
        { property: "og:title", content: "Über Uns | Resumify AI" },
        { property: "og:description", content: "Finden Sie heraus, wie Resumify AI Ihre Karriere unterstützt." },
        { rel: "canonical", href: "https://www.resumify-ai.com/de/about" },
      ],
    },
    "/contact": {
      title: "Kontaktieren Sie Uns | Resumify AI",
      description: "Kontaktieren Sie Resumify AI für Fragen, Support oder Feedback.",
      metaTags: [
        { name: "description", content: "Kontaktieren Sie Resumify AI für Fragen, Support oder Feedback." },
        { name: "keywords", content: "Kontakt, Resumify AI, Unterstützung, Feedback" },
        { property: "og:title", content: "Kontaktieren Sie Uns | Resumify AI" },
        { property: "og:description", content: "Nehmen Sie Kontakt mit Resumify AI auf, um Unterstützung zu erhalten." },
        { rel: "canonical", href: "https://www.resumify-ai.com/de/contact" },
      ],
    },
    "/faq": {
      title: "FAQ | Resumify AI",
      description: "Antworten auf häufig gestellte Fragen zu Resumify AI und unseren Services.",
      metaTags: [
        { name: "description", content: "Antworten auf häufig gestellte Fragen zu Resumify AI und unseren Services." },
        { name: "keywords", content: "FAQ, Häufige Fragen, Resumify AI, Unterstützung" },
        { property: "og:title", content: "FAQ | Resumify AI" },
        { property: "og:description", content: "Finden Sie Antworten auf häufig gestellte Fragen zu Resumify AI." },
        { rel: "canonical", href: "https://www.resumify-ai.com/de/faq" },
      ],
    },
    "/pricing": {
      title: "Preise | Resumify AI",
      description: "Flexible Preispläne von Resumify AI. Finden Sie das passende Angebot für Ihre Karriere.",
      metaTags: [
        { name: "description", content: "Flexible Preispläne von Resumify AI. Finden Sie das passende Angebot für Ihre Karriere." },
        { name: "keywords", content: "Preise, Preispläne, Resumify AI, Karriere Tools" },
        { property: "og:title", content: "Preise | Resumify AI" },
        { property: "og:description", content: "Entdecken Sie flexible Preisoptionen für Resumify AI." },
        { rel: "canonical", href: "https://www.resumify-ai.com/de/pricing" },
      ],
    },
    "/library": {
      title: "Meine Lebensläufe | Resumify AI",
      description: "Verwalten und organisieren Sie Ihre Lebensläufe in der persönlichen Bibliothek.",
      metaTags: [
        { name: "description", content: "Verwalten und organisieren Sie Ihre Lebensläufe in der persönlichen Bibliothek." },
        { name: "keywords", content: "Lebensläufe, CV Verwaltung, Bibliothek, Resumify AI" },
        { property: "og:title", content: "Meine Lebensläufe | Resumify AI" },
        { property: "og:description", content: "Organisieren Sie Ihre gespeicherten Lebensläufe bequem in der Bibliothek." },
        { rel: "canonical", href: "https://www.resumify-ai.com/de/library" },
      ],
    },
    "/account": {
      title: "Mein Konto | Resumify AI",
      description: "Verwalten Sie Ihre Konto-Einstellungen, Abonnements und persönlichen Daten.",
      metaTags: [
        { name: "description", content: "Verwalten Sie Ihre Konto-Einstellungen, Abonnements und persönlichen Daten." },
        { name: "keywords", content: "Mein Konto, Konto Verwaltung, Resumify AI, Abonnements" },
        { property: "og:title", content: "Mein Konto | Resumify AI" },
        { property: "og:description", content: "Greifen Sie einfach auf Ihre Konto-Einstellungen und Informationen zu." },
        { rel: "canonical", href: "https://www.resumify-ai.com/de/account" },
      ],
    },
    "/reset-password": {
      title: "Passwort Zurücksetzen | Resumify AI",
      description: "Sicheres Zurücksetzen Ihres Passworts für Ihr Resumify AI-Konto.",
      metaTags: [
        { name: "description", content: "Sicheres Zurücksetzen Ihres Passworts für Ihr Resumify AI-Konto." },
        { name: "keywords", content: "Passwort Zurücksetzen, Konto Sicherheit, Resumify AI" },
        { property: "og:title", content: "Passwort Zurücksetzen | Resumify AI" },
        { property: "og:description", content: "Setzen Sie Ihr Passwort sicher zurück und greifen Sie wieder auf Ihr Konto zu." },
        { rel: "canonical", href: "https://www.resumify-ai.com/de/reset-password" },
      ],
    },
    "/cv-creation": {
      title: "Lebenslauf Erstellen | Resumify AI",
      description: "Erstellen Sie einen professionellen Lebenslauf mit leistungsstarken Tools.",
      metaTags: [
        { name: "description", content: "Erstellen Sie einen professionellen Lebenslauf mit leistungsstarken Tools." },
        { name: "keywords", content: "Lebenslauf Erstellen, Professioneller Lebenslauf, Resumify AI" },
        { property: "og:title", content: "Lebenslauf Erstellen | Resumify AI" },
        { property: "og:description", content: "Erstellen Sie einen beeindruckenden Lebenslauf mit den Tools von Resumify AI." },
        { rel: "canonical", href: "https://www.resumify-ai.com/de/cv-creation" },
      ],
    },
    "/cv-existing": {
      title: "Bestehenden Lebenslauf Aktualisieren | Resumify AI",
      description: "Optimieren und aktualisieren Sie Ihren Lebenslauf für bessere Jobchancen.",
      metaTags: [
        { name: "description", content: "Optimieren und aktualisieren Sie Ihren Lebenslauf für bessere Jobchancen." },
        { name: "keywords", content: "Lebenslauf Aktualisieren, CV Optimierung, Resumify AI" },
        { property: "og:title", content: "Bestehenden Lebenslauf Aktualisieren | Resumify AI" },
        { property: "og:description", content: "Aktualisieren Sie Ihren Lebenslauf und steigern Sie Ihre Erfolgschancen." },
        { rel: "canonical", href: "https://www.resumify-ai.com/de/cv-existing" },
      ],
    },
    "/cv-evaluation": {
      title: "Lebenslauf Bewerten | Resumify AI",
      description: "Erhalten Sie detaillierte Analysen und Vorschläge zur Verbesserung Ihres Lebenslaufs.",
      metaTags: [
        { name: "description", content: "Erhalten Sie detaillierte Analysen und Vorschläge zur Verbesserung Ihres Lebenslaufs." },
        { name: "keywords", content: "Lebenslauf Bewertung, CV Analyse, Resumify AI" },
        { property: "og:title", content: "Lebenslauf Bewerten | Resumify AI" },
        { property: "og:description", content: "Bewerten Sie Ihren Lebenslauf und verbessern Sie ihn mit Resumify AI." },
        { rel: "canonical", href: "https://www.resumify-ai.com/de/cv-evaluation" },
      ],
    },
    "/payment": {
      title: "Zahlung | Resumify AI",
      description: "Abschließen der Zahlung und Zugriff auf exklusive Funktionen.",
      metaTags: [
        { name: "description", content: "Abschließen der Zahlung und Zugriff auf exklusive Funktionen." },
        { name: "keywords", content: "Zahlung, Premium Funktionen, Resumify AI" },
        { property: "og:title", content: "Zahlung | Resumify AI" },
        { property: "og:description", content: "Führen Sie eine sichere Zahlung durch und schalten Sie Premium-Funktionen frei." },
        { rel: "canonical", href: "https://www.resumify-ai.com/de/payment" },
      ],
    },
    "/policies/imprint": {
      title: "Impressum | Resumify AI",
      description: "Rechtliche Informationen und Unternehmensdaten zu Resumify AI.",
      metaTags: [
        { name: "description", content: "Rechtliche Informationen und Unternehmensdaten zu Resumify AI." },
        { name: "keywords", content: "Impressum, Rechtliche Informationen, Resumify AI" },
        { property: "og:title", content: "Impressum | Resumify AI" },
        { property: "og:description", content: "Erfahren Sie mehr über die rechtlichen Informationen von Resumify AI." },
        { rel: "canonical", href: "https://www.resumify-ai.com/de/policies/imprint" },
      ],
    },
    "/policies/terms-and-conditions": {
      title: "AGB | Resumify AI",
      description: "Lesen Sie die Allgemeinen Geschäftsbedingungen von Resumify AI.",
      metaTags: [
        { name: "description", content: "Lesen Sie die Allgemeinen Geschäftsbedingungen von Resumify AI." },
        { name: "keywords", content: "AGB, Nutzungsbedingungen, Resumify AI" },
        { property: "og:title", content: "AGB | Resumify AI" },
        { property: "og:description", content: "Informieren Sie sich über die Allgemeinen Geschäftsbedingungen von Resumify AI." },
        { rel: "canonical", href: "https://www.resumify-ai.com/de/policies/terms-and-conditions" },
      ],
    },
    "/policies/privacy-policy": {
      title: "Datenschutzerklärung | Resumify AI",
      description: "Erfahren Sie, wie Ihre persönlichen Daten geschützt werden.",
      metaTags: [
        { name: "description", content: "Erfahren Sie, wie Ihre persönlichen Daten geschützt werden." },
        { name: "keywords", content: "Datenschutz, Datenschutzerklärung, Resumify AI" },
        { property: "og:title", content: "Datenschutzerklärung | Resumify AI" },
        { property: "og:description", content: "Lesen Sie die Datenschutzerklärung von Resumify AI." },
        { rel: "canonical", href: "https://www.resumify-ai.com/de/policies/privacy-policy" },
      ],
    },
    "/policies/cancellation-policy": {
      title: "Stornierungsrichtlinie | Resumify AI",
      description: "Erfahren Sie mehr über unsere Stornierungsrichtlinien und wie Sie kündigen können.",
      metaTags: [
        { name: "description", content: "Erfahren Sie mehr über unsere Stornierungsrichtlinien und wie Sie kündigen können." },
        { name: "keywords", content: "Stornierungsrichtlinie, Kündigung, Resumify AI" },
        { property: "og:title", content: "Stornierungsrichtlinie | Resumify AI" },
        { property: "og:description", content: "Erfahren Sie mehr über die Stornierungsrichtlinien von Resumify AI." },
        { rel: "canonical", href: "https://www.resumify-ai.com/de/policies/cancellation-policy" },
      ],
    },
  },
};
const localizedRoutes = Object.keys(translations).flatMap((lang) =>
  baseRoutes.map((route) => {

    const localizedPath = `/${lang}${route.path}`;
    const localizedMeta = translations[lang][route.path] || {};
    return {
      ...route,
      path: localizedPath,
      name: `${route.name}_${lang.toUpperCase()}`,
      meta: {
        ...route.meta,
        title: localizedMeta.title || route.meta?.title,
        metaTags: localizedMeta.metaTags || route.meta?.metaTags,
      },
    };
  }),
);
const routes = [...baseRoutes, ...localizedRoutes];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior: (_to, _from, savedPosition) => {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  },
});

router.afterEach((to) => {

  // Update the document title
  const title = to.meta?.title;
  if (typeof title === "string") {
    document.title = title;
  } else {
    document.title = "Resumify AI";
  }

  // Remove any stale meta tags
  Array.from(document.querySelectorAll("[data-vue-router-controlled]")).forEach(
    (el) => el.parentNode?.removeChild(el),
  );

  // Add new meta tags
  if (to.meta && to.meta.metaTags) {
    (to.meta.metaTags as Array<Record<string, string>>).forEach((tagDef) => {
      const tag = document.createElement("meta");

      Object.keys(tagDef).forEach((key) => {
        tag.setAttribute(key, tagDef[key]);
      });

      tag.setAttribute("data-vue-router-controlled", "");

      document.head.appendChild(tag);
    });
  }

});

router.beforeEach(async (to, _from, next) => {

  const isSmallScreen = window.innerWidth <= 600 || window.innerHeight <= 400;
  const langPrefix = constructLangPrefix();
  console.log("lang", langPrefix);
  setTimeout(() => {
    if (!to.path.startsWith(langPrefix)) {
      const newPath = constructLocalizedPath(to.path);

      router.push({ path: newPath, replace: true });
    } else {
      next();
    }
  }, 100);
  if (isSmallScreen && to.name !== "Info" && to.name !== "Terms-and-conditions" && to.name !== "Reset" && to.name !== "Verify" && to.name !== "Privacy-policy" && to.name !== "Imprint") {
    return next({ name: "Info" });
  }

  addToHistory(_from.fullPath);

  // const isPasswordEntered = localStorage.getItem("isPasswordEntered");
  // if (!isPasswordEntered && to.name !== "Password") {
  //   return next({ name: "Password" });
  // }

  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  const requiresCvOwnership = to.matched.some(record => record.meta.requiresCvOwnership);

  if (to.name === "Payment") {
    const user: any = await new Promise(resolve => {
      const auth = getAuth();
      onAuthStateChanged(auth, user => resolve(user));
    });

    if (user) {
      const jwt = localStorage.getItem("jwt");
      if (jwt) {

        const { result: userData } = await getUserInfo(user.uid);
        if (userData?.accountStatus === "Premium" || userData?.accountStatus === "Onhold") {
          return next({ name: "Account" });
        }

      }
    }
  }
  // router/index.ts

  if (requiresAuth) {
    const user = await new Promise(resolve => {
      const auth = getAuth();
      onAuthStateChanged(auth, user => resolve(user));
    });

    if (!user) {
      return next("/");
    }

    const jwt = localStorage.getItem("jwt");
    if (!jwt) {
      return next("/");
    }

    if (requiresCvOwnership) {
      const { cvId } = to.params;
      try {
        const response = await axios.get(API_URL + `/api/verify-cv-ownership/${cvId}`, {
          headers: {
            "Authorization": `Bearer ${jwt}`,
          },
        });

        if (response.status === 200) {
          next();
        } else {
          next("/");
        }
      } catch (_error) {
        next("/");
      }
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
