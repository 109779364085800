<script setup lang="ts">
import { ref, computed } from "vue";

import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";

import { useAuth } from "@/auth/useAuth";
import AuthModal from "@/components/AuthModal.vue";
import { analyticsCategories } from "@/composables/const";
import { useAnalytics } from "@/composables/useAnalytics";

const { trackEvent } = useAnalytics();
const modalAuthenticationVisible = ref(false);
const authenticationNavigate = ref("");
const { user } = useAuth();
const { t } = useI18n();
const router = useRouter();

defineProps({
  showBasic: {
    type: Boolean,
    default: false,
  },
  userCountry: {
    type: String,
  },
});

const proFeatures = computed(() => [
  t("PricingView.Pro9"),
  t("PricingView.Pro1"),
  t("PricingView.Pro2"),
  t("PricingView.Pro3"),
  t("PricingView.Pro4"),
  t("PricingView.Pro5"),
]);

const proFeatures2 = computed(() => [
  t("PricingView.Pro9"),
  t("PricingView.Pro1"),
  t("PricingView.Pro2"),
  t("PricingView.Pro3"),
  t("PricingView.Pro6"),
  t("PricingView.Pro7"),
]);

const freeFeatures = computed(() => [
  t("PricingView.Pro3"),
  t("PricingView.Free4"),
]);

const goPayment = (premiumType: string) => {
  if (user.value?.authUser) {
    trackEvent("button_click", {
      category: analyticsCategories.UPGRADE,
      action: "click",
      label: premiumType === "monthly" ? "pricing_to_upgrade_monthly" : "pricing_to_upgrade_four_months",
      value: 1,
    });
    sessionStorage.setItem("premiumType", premiumType);
    router.push({ name: "Payment" });
  } else {
    trackEvent("button_click", {
      category: analyticsCategories.AUTH,
      action: "click",
      label: "pricing_to_auth",
      value: 1,
    });
    modalAuthenticationVisible.value = true;
  }
};
const goToIndexPage = () => {
  window.location.href = "/";
};
</script>
<template>
  <AuthModal
    :isVisible="modalAuthenticationVisible"
    :navigateTo="authenticationNavigate"
    @update:visible="modalAuthenticationVisible = $event"
  />
  <div class="main-text">{{ t('PricingView.OurPricingPlan') }}</div>
  <div class="cards-container">
    <div class="card-pricing">
      <div class="card-content">
        <span class="plan-type">{{ t("PricingView.Free") }}</span>
        <span class="plan-price">{{ userCountry === 'TR' ? t("PricingView.PriceFreeTr") : t("PricingView.PriceFree") }}</span>
        <div class="detail-text" style="padding-bottom: 25px;"></div>
        <div class="thin-line"></div>
        <div v-for="text in freeFeatures" :key="text" class="text-icon-row">
          <div>
            <svg class="icon-pricing" viewBox="0 0 512 512">
              <path d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z" fill="#ffffff"></path>
            </svg>
          </div>
          <div>
            <span>{{ text }}</span>
          </div>
        </div>
      </div>
      <button class="custom-button" @click="goToIndexPage">{{ t("PricingView.ContinueFor") }}</button>
    </div>
    <div class="card-pricing-premium" style="border: 4px solid #6741d9;">
      <div class="recommendation-div">
        {{ t("PricingView.MostPopular") }}
      </div>
      <div class="card-content">
        <span class="plan-type">{{ t("PricingView.Pro") }}</span>
        <span class="plan-price">{{ userCountry === 'TR' ? t("PricingView.PriceProTr") : t("PricingView.PricePro") }}<span class="month-text">{{ t("PerMonth") }}</span></span>
        <div class="detail-text">{{ userCountry === 'TR' ? t("PricingView.7DaysExplainTr") : t("PricingView.7DaysExplain") }}</div>
        <div class="thin-line"></div>
        <div style="display: flex; align-items: center; margin-bottom: 20px;">
          <span class="payment-explain">{{ t('payment.stripeExplain1') }}
            <img
              src="@/assets/Stripe.svg"
              alt="Stripe Logo"
              class="stripe-logo"
            /> {{ t('payment.stripeExplain2') }}</span>
        </div>
        <div v-for="text in proFeatures" :key="text" class="text-icon-row">
          <div>
            <svg class="icon-pricing" viewBox="0 0 512 512">
              <path d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z" fill="#ffffff"></path>
            </svg>
          </div>
          <div>
            <span>{{ text }}</span>
          </div>
        </div>
        <div class="text-icon-row">
          <div>
            <svg class="icon-pricing" viewBox="0 0 512 512">
              <path d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z" fill="#ffffff"></path>
            </svg>
          </div>
          <div>
            <span>{{ userCountry === 'TR' ? t("PricingView.ProPayment1Tr") : t("PricingView.ProPayment1") }}</span>
          </div>
        </div>
      </div>
      <button class="custom-button-premium" @click="goPayment('monthly')">{{ t("PricingView.GetStartedWith") }}</button>
    </div>
    <div class="card-pricing-premium">
      <div class="card-content">
        <span class="plan-type">{{ t("PricingView.Pro") }}</span>
        <span class="plan-price">{{ userCountry === 'TR' ? t("PricingView.PriceProFourMonthsTr") : t("PricingView.PriceProFourMonths") }}<span class="month-text">{{ t("PerYear") }}</span></span>
        <div class="detail-text">{{ t("PricingView.4MonthsExplain") }}</div>
        <div class="thin-line"></div>
        <div style="display: flex; align-items: center; margin-bottom: 20px;">
          <span class="payment-explain">{{ t('payment.stripeExplain1') }}
            <img
              src="@/assets/Stripe.svg"
              alt="Stripe Logo"
              class="stripe-logo"
            /> {{ t('payment.stripeExplain2') }}</span>
        </div>
        <div v-for="text in proFeatures2" :key="text" class="text-icon-row">
          <div>
            <svg class="icon-pricing" viewBox="0 0 512 512">
              <path d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z" fill="#ffffff"></path>
            </svg>
          </div>
          <div>
            <span>{{ text }}</span>
          </div>
        </div>
        <div class="text-icon-row">
          <div>
            <svg class="icon-pricing" viewBox="0 0 512 512">
              <path d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z" fill="#ffffff"></path>
            </svg>
          </div>
          <div>
            <span>{{ userCountry === 'TR' ? t("PricingView.ProPayment2Tr") : t("PricingView.ProPayment2") }}</span>
          </div>
        </div>
      </div>
      <button class="custom-button-premium" @click="goPayment('fourMonths')">{{ t("PricingView.GetStartedWith") }}</button>
    </div>
  </div>
</template>

<style scoped>
.cards-container {
  width: 100%;
  display: flex;
  margin-top: 50px;
  padding-top: 20px;
  padding-left: 10px;
  padding-right: 10px;
  justify-content: center;
  padding-bottom: 100px;
  flex-wrap: wrap;
}
.main-text {
    font-size: 30px;
    font-weight: 700;
    text-align: center;
    line-height: 36px;
    margin-top: 50px;
  }

  .card-pricing, .card-pricing-premium {
  min-width: 300px;
  max-width: 350px;
  min-height: 600px;
  background-color: #f0f0f0;
  border-radius: 16px;
  box-shadow: 0px 1px 12px rgba(3, 3, 3, 0.08);
  display: flex;
  flex-direction: column;
  margin: 10px;
  padding: 20px;
  align-items: flex-start;
  text-align: start !important;
  justify-content: space-between;
}
.card-content {
  display: flex;
  justify-content: flex-start;;
  flex-direction: column;
  align-items: flex-start;
  align-self: center;
  width: 100%;
}
.texts {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: flex-start;
}
.text-row {
  display: flex;
  align-items: flex-start;
  margin-bottom: 10px;
}
.text-icon-row {
  display: flex;
  align-items: flex-start;
  margin-bottom: 25px;
  justify-content: flex-start !important;
  flex-wrap: wrap;
}

.card-text {
  margin-top: 10px;
}

.plan-type{
  padding-bottom: 20px;
  padding-top: 20px;
}
.plan-type, .plan-price {
  font-size: 24px;
  font-weight: 700;
  align-self: center;
}

.plan-price {
    color: #21c0a5;
}

.custom-button {
  cursor: pointer;
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
  width: 278px;
  height: 56px;
  padding: 0px 8px;
  border: 1px solid #aa93f3;
  box-sizing: border-box;
  border-radius: 100px;
  background-color: #f7f5ff;
  color: #6741d9;
  font-size: 13px;
  font-weight: 500;
  line-height: 17px;
  outline: none;
  display: block;
  align-self: flex-end;
}

.custom-button-premium {
  cursor: pointer;
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
  width: 278px;
  height: 56px;
  padding: 0px 8px;
  border: 1px solid #aa93f3;
  box-sizing: border-box;
  border-radius: 100px;
  background-color:#aa93f3;
  color: #f7f5ff;
  font-size: 13px;
  font-weight: 500;
  line-height: 17px;
  display: block;
  align-self: flex-end;
}
.icon-pricing {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 25px;
  border: 0;
  box-sizing: border-box;
  border-radius: 50%;
  color: #ffffff;
  background-color: #5438da;
  outline: none;
  padding: 5px;
  margin-right: 10px;
  cursor: default;
  pointer-events: none;
}
.text-icon-row > div:last-child {
  flex: 1;
}
.month-text {
  font-size: 12px;
}
.detail-text{
  font-size: 15px;
  align-self: center;
  margin-top: 0px;
  font-weight: 600px;
  font-family: 'Montserrat';
  color: #6539f4;
}
.thin-line {
  width: 100%;
  height: 0.3px;
  background-color: #474545;
  margin: 15px 0;
  margin-bottom: 40px;
}
.recommendation-div {
  position: absolute;
  top: -20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #6741d9;
  color: white;
  padding: 5px 10px;
  font-size: 20px;
  border-radius: 8px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  font-family: 'Montserrat';
  pointer-events: none;
}
.card-pricing-premium {
  position: relative;
}
.stripe-logo {
  height: 30px;
  vertical-align: middle;
}
</style>
