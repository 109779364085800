import i18n from "@/languages/i18n";

export const getCurrentLocale = () => i18n.global.locale.value;

export const changeLocale = (locale: "tr" | "de" | "en") => {
  if (i18n.global.availableLocales.includes(locale)) {
    i18n.global.locale.value = locale;
    localStorage.setItem("currentLocale", locale);
  } else {
    console.warn(`Locale "${locale}" is not available.`);
  }
  console.log(i18n.global.locale.value);
};