<script setup lang="ts">
import type { Ref } from "vue";
import { computed, onMounted, onUnmounted, ref } from "vue";

import Badge from "primevue/badge";
import Menubar from "primevue/menubar";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";

import resumifyLogo from "@/assets/resumifyLogo.svg";
import { signOutUser } from "@/auth/authService";
import { useAuth } from "@/auth/useAuth";
import AuthModal from "@/components/AuthModal.vue";
import UpgradePremium from "@/components/UpgradePremium.vue";
import { initiaCvData, analyticsCategories } from "@/composables/const";
import { useAnalytics } from "@/composables/useAnalytics";
import { libraryEntryExist, createLibraryEntry, getLibraryEntriesCount } from "@/services/libraryService";

const { trackEvent } = useAnalytics();
const upgradePremiumVisible = ref(false);
const showDropdown = ref(false);
const modalAuthenticationVisible = ref(false);
const authenticationNavigate = ref("");
const dropdown: Ref<HTMLElement | null> = ref(null);
const { t, locale } = useI18n();

const { user } = useAuth();

const router = useRouter();

const languages = ref<Array<{
  name: string,
  code: string,
  flag: string
}>>([
  { name: "English", code: "en", flag: require("@/assets/usa.svg") },
  { name: "Turkish", code: "tr", flag: require("@/assets/turkey.svg") },
  { name: "German", code: "de", flag: require("@/assets/germany.svg") },
]);

const initializeLanguage = () => {
  if (window.URL) {
    const url = window.location.href;

    if (url.includes("tr")) {
      const language =  languages.value[1];
      localStorage.setItem("selectedLanguage", JSON.stringify(language));
      return language;

    } else if (url.includes("de")) {
      const language =  languages.value[2];
      localStorage.setItem("selectedLanguage", JSON.stringify(language));
      return language;

    }
    const language =  languages.value[0];
    localStorage.setItem("selectedLanguage", JSON.stringify(language));
    return language;

  } else {
    const language =  languages.value[0];
    localStorage.setItem("selectedLanguage", JSON.stringify(language));
    return  language;
  }
};

const selectedLanguage = ref(initializeLanguage());

const selectLanguage = (language: {
  name: string,
  code: string,
  flag: string
}) => {
  selectedLanguage.value = language;
  locale.value = language.code;
  showDropdown.value = false;
  localStorage.setItem("selectedLanguage", JSON.stringify(language));

  const currentPath = window.location.pathname;
  const languageCode = language.code;

  const newPath = currentPath.replace(/^\/(tr|de)(\/|$)/, "/");
  console.log("newPath", newPath);

  let updatedPath;
  if (languageCode === "en") {
    updatedPath = newPath;
  } else {
    updatedPath = `/${languageCode}${newPath}`;
  }
  console.log("updatedPath", updatedPath);

  window.location.href = `${window.location.origin}${updatedPath}`;

};

const createCV = async () => {
  if(user.value.authUser){
    const { result: cvExist } = await libraryEntryExist(user.value.authUser.uid);
    if (cvExist && user.value.details?.accountStatus === "Premium") {
      trackEvent("button_click", {
        category: analyticsCategories.CV_CREATION,
        action: "click",
        label: "header_to_library_premium_user",
        value: 1,
      });
      router.push("/library");
    }
    else if(cvExist && user.value.details?.accountStatus !== "Premium"){
      trackEvent("button_click", {
        category: analyticsCategories.CV_CREATION,
        action: "click",
        label: "header_to_library_free_user",
        value: 1,
      });
      router.push("/library");
    }
    else{
      trackEvent("button_click", {
        category: analyticsCategories.CV_CREATION,
        action: "click",
        label: "header_to_create",
        value: 1,
      });
      const { result: cvData } = await createLibraryEntry(initiaCvData, user.value.authUser.uid);
      router.push({ name: "CvCreation", params: { cvId: cvData?.id } });
    }

  }
  else{
    trackEvent("button_click", {
      category: analyticsCategories.CV_CREATION,
      action: "click",
      label: "header_to_auth",
      value: 1,
    });
    authenticationNavigate.value = "creation";
    modalAuthenticationVisible.value = true;
  }
};

const evaluateCV = () => {
  if(user.value.authUser){
    trackEvent("button_click", {
      category: analyticsCategories.CV_EVALUATION,
      action: "click",
      label: "header_to_evaluate",
      value: 1,
    });
    router.push("/cv-evaluation");
  }
  else{
    trackEvent("button_click", {
      category: analyticsCategories.CV_EVALUATION,
      action: "click",
      label: "header_to_auth",
      value: 1,
    });
    authenticationNavigate.value = "evaluation";
    modalAuthenticationVisible.value = true;
  }
};

const createCVExisting = async () => {
  if (user.value?.authUser) {
    const { result } = await getLibraryEntriesCount(user.value?.authUser?.uid);
    if(result?.count !== undefined){
      if (result?.count > 0 && user.value.details?.accountStatus !== "Premium") {
        trackEvent("button_click", {
          category: analyticsCategories.CV_EXISTING,
          action: "click",
          label: "header_to_upgrade",
          value: 1,
        });
        upgradePremiumVisible.value = true;
      } else {
        if(result?.count < 10){
          trackEvent("button_click", {
            category: analyticsCategories.CV_EXISTING,
            action: "click",
            label: "header_to_existing",
            value: 1,
          });
          router.push("/cv-existing");

        }
      }
    }
  }
  else {
    trackEvent("button_click", {
      category: analyticsCategories.CV_EXISTING,
      action: "click",
      label: "header_to_auth",
      value: 1,
    });
    authenticationNavigate.value = "existing";
    modalAuthenticationVisible.value = true;
  }

};

const items = computed(() => {
  return [
    {
      label: t("menu.services"),
      items: [
        {
          label: t("menu.cvEvaluation"),
          command: evaluateCV,
        },
        {
          label: t("menu.cvCreation"),
          command: createCV,
        },
        {
          label: t("menu.cvEnhancement"),
          command: createCVExisting,
        },
      ],
    },
    // {
    //   label: t("menu.pricing"),
    //   command: () => router.push("/pricing"),
    // },
    {
      label: t("menu.aboutUs"),
      command: () => router.push("/about"),
    },
    {
      label: t("menu.contact"),
      command: () => router.push("/contact"),
    },
    {
      label: t("menu.myResumes"),
      command: () => router.push("/library"),
    },
  ];
});

const signOut = async () => {
  try {
    await signOutUser();
  } catch (error: unknown) {
    if (error instanceof Error) {
      console.error(error);
      alert(`Sign out failed: ${error.message}`);
    } else {
      console.error("Unexpected error", error);
      alert("Sign out failed due to an unexpected error.");
    }
  }
};

const isDropdownVisible = ref(false);

const toggleDropdown = () => {
  isDropdownVisible.value = !isDropdownVisible.value;
};

const handleSelect = (value: string) => {
  if(value===t("menu.signOut")){
    signOut();
    toggleDropdown();
    router.push("/");
  }
  else if(value==="Login"){
    authenticationNavigate.value = "";
    modalAuthenticationVisible.value = true;
  }
  else if(value===t("menu.accountSettings")){
    toggleDropdown();
    router.push("/account");

  }
};

const handleClickOutside = (event: MouseEvent) => {
  if (
    dropdown.value &&
    !dropdown.value.contains(event.target as Node) &&
    isDropdownVisible.value
  ) {
    toggleDropdown();
  }
};

const screenSizeSmall = ref(false);

const checkScreenSize = () => {
  if (window.innerWidth < 600 || window.innerHeight < 400) {
    screenSizeSmall.value = true;
  }
  else{
    screenSizeSmall.value = false;
  }
};

onMounted(() => {
  checkScreenSize();
  window.addEventListener("resize", checkScreenSize);
  document.addEventListener("click", handleClickOutside);

  const storedLanguage = localStorage.getItem("selectedLanguage");
  if (storedLanguage) {
    const language = JSON.parse(storedLanguage);
    selectedLanguage.value = language;
    locale.value = language.code;
  }

});

onUnmounted(() => {
  window.removeEventListener("resize", checkScreenSize);
  document.removeEventListener("click", handleClickOutside);
});

const dropdownValues = computed(() => ({
  values: !screenSizeSmall.value ? [
    t("menu.accountSettings"),
    t("menu.signOut"),
  ]
    :[
      t("menu.signOut"),
    ],
}));

</script>

<template>
  <AuthModal :isVisible="modalAuthenticationVisible" :navigateTo="authenticationNavigate" @update:visible="modalAuthenticationVisible = $event"/>
  <UpgradePremium v-if="upgradePremiumVisible" showFeatures @close="upgradePremiumVisible = false"/>
  <div style="padding-right: 20px;" class="header-wrapper">
    <div class="logo-container">
      <img :src="resumifyLogo" alt="Resumify Logo" class="logo" />
      <router-link :to="'/'" class="app-name">Resumify AI</router-link>
    </div>
    <Menubar v-if="!screenSizeSmall" :model="items" class="header-bar">
      <template #item="{ item, props, hasSubmenu, root }">
        <a v-ripple class="flex align-items-center" v-bind="props.action" >
          <span :class="item.icon" />
          <span :class="[item.className, 'ml-2']">{{ item.label }}</span>
          <Badge v-if="item.badge" :class="{ 'ml-auto': !root, 'ml-2': root }" :value="item.badge" />
          <span v-if="item.shortcut" class="ml-auto border-1 surface-border border-round surface-100 text-xs p-1">{{ item.shortcut }}</span>
          <i v-if="hasSubmenu" :class="['pi pi-angle-down', { 'pi-angle-down ml-2': root, 'pi-angle-right ml-auto': !root }]"></i>
        </a>
      </template>
    </Menubar>
    <div class="language-selector" @click="showDropdown = !showDropdown">
      <img :src="selectedLanguage.flag" :alt="selectedLanguage.name" class="flag-icon">
      <div v-if="showDropdown" class="dropdown-content">
        <div v-for="lang in languages" :key="lang.code" @click.stop="selectLanguage(lang)">
          <img :src="lang.flag" :alt="lang.name" class="flag-icon">
        </div>
      </div>
    </div>
    <button  v-if="!user.authUser" class="get-started" @click="handleSelect('Login')">{{ t('menu.login') }}</button>
    <div class="dropdown-container">
      <button v-if="user.authUser" ref="dropdown" class="button-style" @click="toggleDropdown">
        <font-awesome-icon :icon="['far', 'user']" />
      </button>
      <div v-if="isDropdownVisible && user.authUser" class="dropdown-content-user">
        <a
          v-for="value in dropdownValues.values"
          :key="value"
          class="dropdown-item"
          @click="handleSelect(value)"
        >{{ value }}</a>
      </div>

    </div>
  </div>
</template>

<style scoped>

.dropdown-container {
  position: relative;
  display: inline-block;
  margin-right: 20px;
}

.dropdown-content-user {
  display: block;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
  z-index: 1;
  top: 100%;
  right: 0;
}

.dropdown-item {
  padding: 10px 20px;
  display: block;
  cursor: pointer;
}

.dropdown-item:hover {
  background-color: #f1f1f1;
}

.icon-style {
  color: #ffffff;
  fill: #ffffff;
  width: 14px;
  height: 14px;
  font-size: 14px;
}

.button-style {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 31px;
  height: 31px;
  border: 0;
  box-sizing: border-box;
  border-radius: 32px;
  color: #ffffff;
  background-color: #5438da;
  outline: none;
}

.language-selector {
  position: relative;
  cursor: pointer;
  display: inline-block;
  margin-right: 40px;
  margin-left: 10px;
}

.flag-icon {
  width: 24px;
  height: auto;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 30px;
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
  z-index: 1;
}

.language-selector:hover .dropdown-content {
  display: block;
}

.dropdown-content div {
  padding: 12px 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.dropdown-content div img {
  margin-right: 10px;
}
.header-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 10px;
  background-color: #f2f2f2;
  width: 100%;
  border: none;
  border-bottom: 1px solid #3164f4;
  box-sizing: border-box;
}
.app-name {
  flex-grow: 1;
  font-size: 1.5rem;
  color: #333;
  font-weight: bold;
  text-align: left;
  background-color: #f2f2f2;
  text-decoration: none;
  border: none;
  padding-left: 10px;
  align-items: center;
  display: flex;
  padding-top: 10px;
}

.header-bar {
  justify-content: flex-end;
  flex-grow: 4;
  display: flex;
  background-color: #f2f2f2;
  border: none;
}

.p-dropdown {
  border: none;
  background-color: #f2f2f2;
}

.language-dropdown .p-dropdown, .language-dropdown .p-dropdown .p-dropdown-label,
.language-dropdown .p-dropdown .p-dropdown-trigger {
  border: none;
  background-color: #f2f2f2;
  color: black;
}

.flex {
  display: flex;
  align-items: center;
}
.get-started {
  top: 24px;
  left: 1274px;
  width: 120px;
  height: 35px;
  padding: 0px 8px;
  border: 0;
  box-sizing: border-box;
  border-radius: 5px;
  background-color: #5438da;
  color: #ffffff;
  font-size: 14px;
  font-family: 'Montserrat';
  font-weight: 400;
  line-height: 17px;
  outline: none;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  display: flex;
  margin-right: 30px;
}

.mr-2, .ml-2, .profile-icon, .mr-2, span:not(.resumify-button) {
  margin-right: 0.20rem;
  margin-left: 0.20rem;
  font-size: 0.9rem;
  padding: 2px;
}

.font-bold {
  font-weight: bold;
}

.h-2rem {
  height: 2rem;
}

.align-items-center > * {
  margin-bottom: 5px;
}

.logo-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.logo {
  width: 40px;
  height: auto;
  margin-right: 10px;
}

@media (max-width: 600px) {
  .app-name {
    font-size: 1rem;
    width: 140px;
    margin-bottom: 10px;
  }
  .logo{
    margin-bottom: 13px;
  }
  .get-started{
    margin-right: 0px;
  }
}
</style>
