<script setup lang="ts">
import { ref, defineEmits, watch, nextTick, onMounted, onUnmounted } from "vue";

import type { Stripe, StripeElements, StripeCardNumberElement, StripeCardExpiryElement, StripeCardCvcElement } from "@stripe/stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useI18n } from "vue-i18n";

import { useAuth , updateUserDetails } from "@/auth/useAuth";
import ProcessPayment from "@/components/ProcessPayment.vue";
import router from "@/router";
import { createSubscription, createPayPalCheckoutSession } from "@/services/paymentService";
import { updatePremium, updatePremiumFourMonths, getUserInfo, updateOnhold } from "@/services/userService";

const { t } = useI18n();
const { user } = useAuth();

const showPaymentProcess = ref(false);
const startProcessing = ref(false);
const processingFailed = ref(false);
const processingSuccess = ref(false);
const paymentActionRequired = ref(false);
const isCheckedTerms = ref(false);
const isCheckedPrivacy = ref(false);
const gotPremium = ref(false);
const buttonDisabled = ref(false);

const stripePromise = loadStripe(process.env.NODE_ENV === "production" ? "pk_live_51QAa3KP6MejSt1XEn8IwnUshGziERECKUcpXQUHDJXGdPtlO28QzrFLyz6z6GSecVcFx3frZad6Jcr1JSQhsOaRF00XXL395ZU" : "pk_test_51QAa3KP6MejSt1XEKFYAKlKugmu84cijPEhrA7nkWLo1c6TkqjrQFKBRX4OYWLm4eBZ7Zxewta0dqmJHAF4MIprT00KfPY7ywE");
const elements = ref<StripeElements | null>(null);
const stripe = ref<Stripe | null>(null);
const cardNumberElement = ref<StripeCardNumberElement | null>(null);
const cardExpiryElement = ref<StripeCardExpiryElement | null>(null);
const cardCvcElement = ref<StripeCardCvcElement | null>(null);
const error = ref<string | null>(null);
const premiumType = ref("");
const userCountry = ref("");

const emit = defineEmits(["close"]);

const closeComponent = (success: boolean) => {
  emit("close", success);
  showPaymentProcess.value = false;
};

const toggleCheckTerms = () => {
  isCheckedTerms.value = !isCheckedTerms.value;
};

const toggleCheckPrivacy = () => {
  isCheckedPrivacy.value = !isCheckedPrivacy.value;
};

const selectedCard = ref<string | null>(null);

const selectCard = (card: string) => {
  selectedCard.value = card;
};

const fullName = ref("");
const emailAddress = ref("");
const streetAddress = ref("");
const city = ref("");
const zipPostal = ref("");
const nameOnCard = ref("");

watch(selectedCard, async (newValue) => {
  if (newValue === "credit") {
    await nextTick();
    stripe.value = await stripePromise;

    if (stripe.value) {
      elements.value = stripe.value.elements();

      // Create individual elements
      cardNumberElement.value = elements.value.create("cardNumber");
      cardNumberElement.value.mount("#card-number-element");

      cardExpiryElement.value = elements.value.create("cardExpiry");
      cardExpiryElement.value.mount("#card-expiry-element");

      cardCvcElement.value = elements.value.create("cardCvc");
      cardCvcElement.value.mount("#card-cvc-element");
    } else {
      error.value = "Stripe failed to initialize.";
    }
  }
});

const createSubscriptionProcess = async () => {
  const currency = userCountry.value === "TR" ? "try" : "eur";
  if (!user.value?.details?.uid && !user.value?.details?.email) {
    processingFailed.value = true;
    return;
  }

  error.value = null;
  processingFailed.value = false;
  processingSuccess.value = false;
  paymentActionRequired.value = false;

  if (
    !stripe.value ||
    !elements.value ||
    !cardNumberElement.value ||
    !cardExpiryElement.value ||
    !cardCvcElement.value
  ) {
    error.value = "Stripe is not initialized properly";
    processingFailed.value = true;
    startProcessing.value = false;
    return;
  }

  try {

    const { paymentMethod, error: paymentMethodError } =
      await stripe.value.createPaymentMethod({
        type: "card",
        card: cardNumberElement.value,
        billing_details: {
          name: fullName.value,
          email: user.value.details.email,
          address: {
            line1: streetAddress.value,
            city: city.value,
            postal_code: zipPostal.value,
          },
        },
      });

    if (paymentMethodError) {
      error.value =
        paymentMethodError.message ||
        "An unexpected error occurred while creating the payment method";
      processingFailed.value = true;
      startProcessing.value = false;
      return;
    }

    const { data, error: apiError } = await createSubscription({
      uid: user.value.details.uid,
      paymentMethodId: paymentMethod?.id,
      country: userCountry.value,
      premiumType: premiumType.value,
    });

    const paymentIntentStatus = data.value?.paymentIntentStatus;
    if (apiError.value || paymentIntentStatus === "payment_failed") {
      error.value = apiError.value || "Failed to create subscription";
      processingFailed.value = true;
      startProcessing.value = false;

      return;
    }
    const clientSecret = data.value?.clientSecret;
    const subscriptionId = data.value?.subscriptionId;

    if (!clientSecret || !subscriptionId) {
      error.value = "No client secret returned from server";
      processingFailed.value = true;
      startProcessing.value = false;
      return;
    }

    if (paymentIntentStatus === "requires_confirmation" || paymentIntentStatus === "requires_action") {
      paymentActionRequired.value = true;
      await updateOnhold(user.value.details.uid, "Onhold");

      const { error: paymentError, paymentIntent } =
        await stripe.value.confirmCardPayment(clientSecret);

      if (paymentError) {
        error.value =
          paymentError.message ||
          "An unexpected error occurred during payment confirmation";
        processingFailed.value = true;
        paymentActionRequired.value = false;
        startProcessing.value = false;
      } else if (paymentIntent?.status === "succeeded") {
        paymentActionRequired.value = false;
        startProcessing.value = true;
        if(premiumType.value === "monthly"){
          const { result: userDetails } =  await updatePremium(user.value.details.uid, premiumType.value, currency, subscriptionId );
          gotPremium.value = true;
          if(userDetails){
            updateUserDetails(userDetails);
          }
        }
        else{
          const { result: userDetails } =  await updatePremiumFourMonths(user.value.details.uid, premiumType.value, currency, subscriptionId );
          gotPremium.value = true;
          if(userDetails){
            updateUserDetails(userDetails);
          }
        }
        processingSuccess.value = true;
        startProcessing.value = false;
        paymentActionRequired.value = false;
      }
    } else if (paymentIntentStatus === "succeeded") {
      if(premiumType.value === "monthly"){
        const { result: userDetails } =  await updatePremium(user.value.details.uid, premiumType.value, currency, subscriptionId );
        gotPremium.value = true;
        if(userDetails){
          updateUserDetails(userDetails);
        }
      }
      else{
        const { result: userDetails } =  await updatePremiumFourMonths(user.value.details.uid, premiumType.value, currency, subscriptionId );
        gotPremium.value = true;
        if(userDetails){
          updateUserDetails(userDetails);
        }
      }
      processingSuccess.value = true;
      startProcessing.value = false;
      paymentActionRequired.value = false;
    }
  } catch (error: any) {
    error.value =
      error.message || "An error occurred during the subscription process";
    processingFailed.value = true;
    startProcessing.value = false;
    paymentActionRequired.value = false;
  }
};

const createPayPalSubscriptionProcess = async () => {
  if (!user.value?.details?.uid) {
    processingFailed.value = true;
    return;
  }

  error.value = null;
  processingFailed.value = false;
  processingSuccess.value = false;

  try {
    const { data, error: apiError } = await createPayPalCheckoutSession({
      uid: user.value.details.uid,
      country: userCountry.value,
      premiumType: premiumType.value,
    });

    if (apiError.value) {
      error.value = apiError.value || "Failed to create PayPal Checkout session";
      processingFailed.value = true;
      startProcessing.value = false;
      return;
    }

    const checkoutUrl = data.value?.checkoutUrl;

    if (!checkoutUrl) {
      error.value = "No checkout URL returned from server";
      processingFailed.value = true;
      startProcessing.value = false;
      return;
    }

    window.location.href = checkoutUrl;

  } catch (error: any) {
    error.value =
      error.message || "An error occurred during the PayPal subscription process";
    processingFailed.value = true;
    startProcessing.value = false;
  }
};

const validationErrors = ref({
  fullName: false,
  emailAddress: false,
  streetAddress: false,
  city: false,
  zipPostal: false,
  nameOnCard: false,
  termsAccepted: false,
  privacyAccepted: false,
});

watch(fullName, () => {
  validationErrors.value.fullName = false;
});

watch(emailAddress, () => {
  validationErrors.value.emailAddress = false;
});

watch(streetAddress, () => {
  validationErrors.value.streetAddress = false;
});

watch(city, () => {
  validationErrors.value.city = false;
});

watch(zipPostal, () => {
  validationErrors.value.zipPostal = false;
});

watch(nameOnCard, () => {
  validationErrors.value.nameOnCard = false;
});

watch(isCheckedTerms, () => {
  validationErrors.value.termsAccepted = false;
});

watch(isCheckedPrivacy, () => {
  validationErrors.value.privacyAccepted = false;
});

const validateInputs = () => {
  validationErrors.value.fullName = !fullName.value.trim();
  validationErrors.value.emailAddress = !emailAddress.value.trim() || !/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(emailAddress.value);
  validationErrors.value.streetAddress = !streetAddress.value.trim();
  validationErrors.value.city = !city.value.trim();
  validationErrors.value.zipPostal = !zipPostal.value.trim();
  validationErrors.value.nameOnCard = !nameOnCard.value.trim();
  validationErrors.value.termsAccepted = !isCheckedTerms.value;
  validationErrors.value.privacyAccepted = !isCheckedPrivacy.value;

  return Object.values(validationErrors.value).every((error) => !error);
};

const handleBuy = async () => {
  if(user.value.authUser?.uid){

    const { result: userData } = await getUserInfo(user.value.authUser?.uid);

    if(gotPremium.value || userData?.accountStatus === "Premium" || userData?.accountStatus === "Onhold" ){
      if(userData){
        updateUserDetails(userData);
      }
      router.push({ name: "Account" });
    }

    if (selectedCard.value === "credit") {
      if (validateInputs()) {
        showPaymentProcess.value = true;
        startProcessing.value = true;
        createSubscriptionProcess();
      }
    } else {
      showPaymentProcess.value = true;
      startProcessing.value = true;
      createPayPalSubscriptionProcess();
    }
  }
};

onMounted(() => {
  premiumType.value = sessionStorage.getItem("premiumType") || "";
  userCountry.value = sessionStorage.getItem("userCountry") || "";
  if(premiumType.value === "" || userCountry.value === ""){
    router.push({ name: "Pricing" });
  }
});

onUnmounted(() => {
  sessionStorage.removeItem("premiumType");
  sessionStorage.removeItem("userCountry");
});

</script>

<template v-if="premiumType && userCountry">
  <ProcessPayment
    v-if="showPaymentProcess && user.authUser"
    :startProcessing="startProcessing"
    :premiumType="premiumType"
    :authUser="user.authUser"
    :processingSuccess="processingSuccess"
    :processingFailed="processingFailed"
    :paymentActionRequired="paymentActionRequired"
    @close="closeComponent"
  />
  <div class="payment-div">
    <div style="margin-bottom: 25px;">
      <span class="payment-text">{{ t('payment.title') }}</span>
    </div>
    <div style="margin-bottom: 10px;">
      <span class="payment-explain">{{ t('payment.explain') }}</span>
    </div>
    <div style="display: flex; align-items: center; margin-bottom: 50px;">
      <span class="payment-explain">{{ t('payment.stripeExplain1') }}  <img
        src="@/assets/Stripe.svg"
        alt="Stripe Logo"
        class="stripe-logo"
      /> {{ t('payment.stripeExplain2') }}</span>
    </div>

    <div class="payment-cards-div">
      <div
        v-if="userCountry !== 'TR'"
        class="payment-card"
        :class="{ selected: selectedCard === 'paypal' }"
        @click="selectCard('paypal')"
      >
        <!-- <div
        v-if="userCountry !== 'TR'"
        class="payment-card-paypal"
        :class="{ selected: selectedCard === 'paypal' }"
      > -->
        <svg v-if="selectedCard === 'paypal'" class="tick-icon" viewBox="0 0 24 24">
          <path fill="none" d="M0 0h24v24H0z"></path>
          <path d="M22 5.18 10.59 16.6l-4.24-4.24 1.41-1.41 2.83 2.83 10-10L22 5.18zm-2.21 5.04c.13.57.21 1.17.21 1.78 0 4.42-3.58 8-8 8s-8-3.58-8-8 3.58-8 8-8c1.58 0 3.04.46 4.28 1.25l1.44-1.44A9.9 9.9 0 0 0 12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10c0-1.19-.22-2.33-.6-3.39l-1.61 1.61z"></path>
        </svg>
        <img :src="require('@/assets/paypal.svg')" class="paypal-image" />
        <!-- <span class="paypal-disabled-text">{{ t('payment.paypalDisabled') }}</span> -->
      </div>

      <div
        class="payment-card"
        :class="{ selected: selectedCard === 'credit' }"
        @click="selectCard('credit')"
      >
        <svg v-if="selectedCard === 'credit'" class="tick-icon" viewBox="0 0 24 24">
          <path fill="none" d="M0 0h24v24H0z"></path>
          <path d="M22 5.18 10.59 16.6l-4.24-4.24 1.41-1.41 2.83 2.83 10-10L22 5.18zm-2.21 5.04c.13.57.21 1.17.21 1.78 0 4.42-3.58 8-8 8s-8-3.58-8-8 3.58-8 8-8c1.58 0 3.04.46 4.28 1.25l1.44-1.44A9.9 9.9 0 0 0 12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10c0-1.19-.22-2.33-.6-3.39l-1.61 1.61z"></path>
        </svg>
        <div class="credit-card-image-div">
          <img :src="require('@/assets/mastercard.svg')" class="credit-card-image" />
          <img :src="require('@/assets/visa.svg')" class="credit-card-image" />
        </div>
        <span class="credit-card-text">{{ t('payment.creditCard') }}</span>

      </div>

    </div>

    <div v-if="selectedCard === 'credit'" class="payment-info-main-div">
      <div  class="payment-info-div">
        <div class="payment-info-title-div">
          <div class="number-div">
            <span class="number-text">1</span>
          </div>
          <span>{{ t('payment.billingAddress') }}</span>
        </div>
        <input
          v-model="fullName"
          class="payment-info-card"
          :class="{ 'invalid-input': validationErrors.fullName}"
          :placeholder="t('payment.fullName')"
        />
        <input
          v-model="emailAddress"
          class="payment-info-card"
          :class="{ 'invalid-input': validationErrors.emailAddress }"
          :placeholder="t('payment.emailAddress')"
        />
        <input
          v-model="streetAddress"
          class="payment-info-card"
          :class="{ 'invalid-input': validationErrors.streetAddress }"
          :placeholder="t('payment.streetAddress')"
        />
        <div class="double-info-card-div">
          <input
            v-model="city"
            class="double-payment-info-card"
            :class="{ 'invalid-input': validationErrors.city }"
            :placeholder="t('payment.city')"
          />
          <input
            v-model="zipPostal"
            class="double-payment-info-card"
            :class="{ 'invalid-input': validationErrors.zipPostal }"
            :placeholder="t('payment.zipPostal')"
          />
        </div>
      </div>
      <div  class="payment-info-div">
        <div class="payment-info-title-div">
          <div class="number-div">
            <span class="number-text">2</span>
          </div>
          <span>{{ t('payment.creditCardInfo') }}</span>
        </div>
        <input
          v-model="nameOnCard"
          class="payment-info-card"
          :class="{ 'invalid-input': validationErrors.nameOnCard }"
          :placeholder="t('payment.nameOnCard')"
        />
        <div id="card-number-element" class="payment-info-card" :placeholder="t('payment.cardNumber')"></div>
        <div class="double-info-card-div">

          <div id="card-expiry-element" class="double-payment-info-card"></div>

          <div id="card-cvc-element" class="double-payment-info-card"></div>
        </div>

        <div class="terms-container" :class="{ 'invalid-checkbox': validationErrors.termsAccepted }">
          <div class="container" @click="toggleCheckTerms">
            <input id="terms-checkbox" v-model="isCheckedTerms" type="checkbox" class="input" />
            <div v-if="isCheckedTerms" class="check">✔</div>
          </div>
          <label for="terms-checkbox" class="terms-text">
            <a href="https://stripe.com/legal/consumer" target="_blank">{{ t('payment.acceptTerms') }}</a>
          </label>
        </div>
        <div class="terms-container" :class="{ 'invalid-checkbox': validationErrors.privacyAccepted }">
          <div class="container" @click="toggleCheckPrivacy">
            <input id="privacy-checkbox" v-model="isCheckedPrivacy" type="checkbox" class="input" />
            <div v-if="isCheckedPrivacy" class="check">✔</div>
          </div>
          <label for="privacy-checkbox" class="terms-text">
            <a href="https://stripe.com/privacy" target="_blank">{{ t('payment.acceptPrivacy') }}</a>
          </label>
        </div>

      </div>
    </div>
    <div class="buttons-div">
      <button class="back-button" @click="router.back">
        <span>{{ t('payment.back') }}</span>
      </button>
      <button class="buy-button" :disabled="!selectedCard?.length || buttonDisabled" @click="handleBuy">
        <span>{{ t('payment.buy') }}</span>
      </button>
    </div>

  </div>

</template>

<style scoped>

.buttons-div{
    width: 62%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 100px;
}

.credit-card-image-div{
    display: flex;
    flex-direction: row;
    gap: 10px;
    margin-bottom: 20px;
}

.invalid-checkbox .container {
  border: 2px solid red !important;
  padding: 2px !important;
  border-radius: 4px !important;
}

.invalid-input {
  border: 2px solid red !important;
}

.tick-icon{
  position: absolute;
  top: -18px;
  right: -5px;
  color: #5438da;
  fill: #5438da;
  font-size: 40px;
  width: 40px;
  height: 40px;
}

.buy-button{
 display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  width: 362px;
  height: 44px;
  padding: 0px 8px;
  border: 0;
  box-sizing: border-box;
  border-radius: 8px;
  box-shadow: 0px 0px 10px rgba(0,0,0,0.1);
  background-color: #5438da;
  color: #ffffff;
  font-size: 14px;
  font-family: "Roboto";
  line-height: 24px;
  outline: none;
  margin-top: 53px;
}

.container {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  width: 20px;
  height: 20px;
  opacity: 1;
  pointer-events: auto;
  color: rgba(0, 0, 255, 1);
  background-color: rgba(255, 255, 255, 1);
  border-radius: 5px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(0, 0, 255, 1);
  position: relative;
}

.check {
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgba(0, 0, 255, 1);
  transition: left 0.3s ease;
  z-index: 1;
  font-size: 14px;
}

.input {
  position: absolute;
  opacity: 0;
  visibility: hidden;
  width: 1px;
  height: 1px;
  pointer-events: none;
}

.terms-container {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.terms-text {
  margin-left: 10px;
  font-family: 'Poppins', sans-serif;
  color: #000;
  font-size: 13px;
}

.back-button{
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  width: 126px;
  height: 44px;
  padding: 0px 8px;
  border: 0;
  box-sizing: border-box;
  border-radius: 8px;
  box-shadow: 0px 0px 10px rgba(0,0,0,0.1);
  background-color: #f0f0f0;
  color: #000000;
  font-size: 14px;
  font-family: "Roboto";
  line-height: 24px;
  outline: none;
  margin-top: 40px;
}

.double-info-card-div{
    display: flex;
    flex-direction: row;
    gap: 28px;
}

.double-payment-info-card{
    width: 182px;
    height: 53px;
    background-color: #f0f0f0;
    border-radius: 4px;
    border: none;
    padding: 10px;
    font-family: 'Poppins', sans-serif;
    margin-bottom: 20px;
}

.double-payment-info-card::placeholder {
    color: #a0a0a0;
  }

  .double-payment-info-card:hover {
    border: 2px solid rgba(95, 82, 210, 0.7);
  }

.payment-info-card {
    width: 392px;
    height: 53px;
    background-color: #f0f0f0;
    border-radius: 4px;
    border: none;
    padding: 10px;
    font-family: 'Poppins', sans-serif;
    margin-bottom: 20px;
  }

  .payment-info-card::placeholder {
    color: #a0a0a0;
  }
  .payment-info-card:hover {
    border: 2px solid rgba(95, 82, 210, 0.7);
  }

.number-text{
    color: #000000;
  font-size: 14px;
  font-family: "Roboto";
  line-height: 16px;
}

.number-div{
    display: flex;
    width: 40px;
    height: 40px;
    border: 3px solid black;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
}

.payment-info-title-div{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    margin-bottom: 20px;
}

.payment-info-div{
    display: flex;
    flex-direction: column;
    width: 50%;
}

.payment-info-main-div{
    display: flex;
    flex-direction: row;
    width: 820px;
}

.credit-card-text{
    color: #000000;
  font-size: 14px;
  font-family: "Roboto";
  line-height: 16px;
}

.paypal-disabled-text{
    color: #000000;
  font-size: 14px;
  font-family: "Roboto";
  line-height: 16px;
  margin-top: 10px;
}

.paypal-image{
  width: 160px;
  height: 41px;
  border-radius: 8px;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}
.klarna-image{
    width: 140px;
  height: 71px;
  border-radius: 8px;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}
.credit-card-image{
    width: 53px;
  height: 37px;
  border-radius: 8px;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}

.payment-card{
display: flex;
  width: 248px;
  height: 136px;
  background-color: #f0f0f0;
  border-radius: 26px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
}

.payment-card-paypal{
display: flex;
  width: 248px;
  height: 136px;
  background-color: #c3c2c2;
  border-radius: 26px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
}

.payment-card.selected {
  border: 4px solid #5438da;
  box-sizing: border-box;
}

.payment-cards-div{
    display: flex;
    flex-direction: row;
    gap: 30px;
    margin-bottom: 80px;
    position: relative;
}

.payment-div{
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: start;
    justify-content: start;
    padding: 100px;
}

.payment-text{
    color: #030303;
    font-size: 24px;
    font-family: 'Poppins', sans-serif;
    line-height: 31px;
}

.payment-explain{
    color: #030303;
    font-size: 16px;
    font-family: 'Poppins', sans-serif;
    line-height: 21px;
}
.stripe-container {
  display: flex;
  align-items: center;
  margin-bottom: 50px;
}

.stripe-logo {
  height: 40px;
  vertical-align: middle;
}
</style>