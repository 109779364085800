<template>
  <div class="small-screen-message">
    For the best experience, please view this website on a larger screen, such as a computer or tablet.
  </div>
</template>

<script setup lang="ts">
import { onMounted, onUnmounted } from "vue";

import { useRouter } from "vue-router";

const router = useRouter();

const checkScreenSizeAndRedirect = () => {
  if (window.innerWidth > 600 && window.innerHeight > 400) {
    router.push("/");
  }
};

onMounted(() => {
  checkScreenSizeAndRedirect();
  window.addEventListener("resize", checkScreenSizeAndRedirect);
});

onUnmounted(() => {
  window.removeEventListener("resize", checkScreenSizeAndRedirect);
});
</script>

<style scoped>
.small-screen-message {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  color: white;
  font-size: 18px;
  text-align: center;
  z-index: 1000;
}
</style>
