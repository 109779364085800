<script setup lang="ts">
import { ref } from "vue";

import type { AxiosResponse } from "axios";
import axios from "axios";
import { GlobalWorkerOptions, getDocument } from "pdfjs-dist";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import { useStore } from "vuex";

import { useAuth } from "@/auth/useAuth";
import CvExistingLoader from "@/components/CvExistingLoader.vue";
import UpgradePremium from "@/components/UpgradePremium.vue";
import { analyticsCategories } from "@/composables/const";
import { useAnalytics } from "@/composables/useAnalytics";
import { API_URL } from "@/config";
import { createLibraryEntry } from "@/services/libraryService";
import { checkFileValidty } from "@/utils/file";

GlobalWorkerOptions.workerSrc = "pdfjs-dist/build/pdf.worker.js";

const { trackEvent } = useAnalytics();

const { t } = useI18n();
const router = useRouter();
const { user } = useAuth();
const fileInput = ref<HTMLElement | null>(null);
const store = useStore();
const isModalOpen = ref(false);
const isDragging = ref(false);
const creditNotEnough = ref(false);

const invalidFileError = t("InvalidFileType");
const fileTooLargeError = t("FileTooLarge");

interface CvComponents {
  Contact: object;
  Education: [];
  Experience: [];
  Certification: [];
  Skill: [];
  Project: [];
  Language: [];
  Reference: [];
  Hobby: [];
  Publication: [];
  Honor: [];
}

interface CreateCvResponse {
  allComponents: CvComponents;
}

const clickUpload = () => {
  fileInput.value?.click();
};

const updateSections = async (data: CreateCvResponse) => {
  const allComponents = data.allComponents;
  const sectionsToUpdate = [
    { section: "contact", data: allComponents.Contact },
    { section: "educations", data: allComponents.Education },
    { section: "experiences", data: allComponents.Experience },
    { section: "certifications", data: allComponents.Certification },
    { section: "skills", data: allComponents.Skill },
    { section: "projects", data: allComponents.Project },
    { section: "languages", data: allComponents.Language },
    { section: "references", data: allComponents.Reference },
    { section: "hobbies", data: allComponents.Hobby },
    { section: "publications", data: allComponents.Publication },
    { section: "honors", data: allComponents.Honor },
  ];

  await sectionsToUpdate.reduce(async (promiseChain, { section, data }) => {
    await promiseChain;
    return store.dispatch("updateCvSection", { section, data });
  }, Promise.resolve());

  const { result: cvData } = await createLibraryEntry(store.state.cvData, user.value.authUser?.uid || "");
  isModalOpen.value = false;
  router.push({ name: "CvCreation", params: { cvId: cvData?.id } });
};

const fetchFeedback = async (text: string) => {
  try {
    isModalOpen.value = true;
    const csrfResponse = await axios.get(API_URL + "/csrf-token", { withCredentials: true });
    const csrfToken = csrfResponse.data.csrfToken;

    const response: AxiosResponse<CreateCvResponse> = await axios({
      method: "POST",
      url: API_URL + "/createCVFromExisting",
      data: JSON.stringify({ cvText: text, uid: user.value.details?.id || "" }),
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": csrfToken,
      },
      withCredentials: true,
    });

    if (!response.data) {
      throw new Error("Network response was not ok");
    }

    const data = await response.data;
    updateSections(data);

  } catch (error: any) {
    isModalOpen.value = false;
    if(error?.response?.status === 402){
      trackEvent("button_click", {
        category: analyticsCategories.UPGRADE,
        action: "click",
        label: "existing_to_upgrade",
        value: 1,
      });
      creditNotEnough.value = true;
    }
  }
};

const processFile = async (file: File) => {
  if (!checkFileValidty(file, invalidFileError, fileTooLargeError)) {return;}

  const fileReader = new FileReader();
  fileReader.onload = async (ev) => {
    try {
      const typedArray = new Uint8Array(ev?.target?.result as ArrayBuffer);
      const loadingTask = getDocument({ data: typedArray });
      const pdf = await loadingTask.promise;
      let textContent = "";

      for (let pageNum = 1; pageNum <= pdf.numPages; pageNum++) {
        const page = await pdf.getPage(pageNum);
        const text = await page.getTextContent();
        text.items.forEach((item) => {
          if ("str" in item) {
            textContent += item.str + " ";
          }
        });
      }

      fetchFeedback(textContent);

    } catch (error) {
      console.error("Error extracting text from PDF:", error);
    }
  };
  fileReader.readAsArrayBuffer(file);
};
const handleFileUpload = async (event: Event) => {
  const files = (event.target as HTMLInputElement).files;
  if (files && files[0]) {
    await processFile(files[0]);
  }
};

const handleDragOver = (event: DragEvent) => {
  event.preventDefault();
  isDragging.value = true;
};

const handleDragLeave = () => {
  isDragging.value = false;
};

const handleDrop = async (event: DragEvent) => {
  event.preventDefault();
  isDragging.value = false;

  const files = event.dataTransfer?.files;
  if (files && files[0]) {
    await processFile(files[0]);
  }
};

</script>

<template>
  <UpgradePremium v-if="creditNotEnough" :showFeatures="(user.details?.accountStatus === 'Free' || user.details?.accountStatus === 'Onhold') ? true :false" :premiumReasonText="(user.details?.accountStatus === 'Free' || user.details?.accountStatus === 'Onhold') ? t('NotEnoughCreditsTextFree') : t('NotEnoughCreditsTextPremium')" :premiumHeader="(user.details?.accountStatus === 'Free' || user.details?.accountStatus === 'Onhold') ? t('NotEnoughCreditsHeaderFree') : t('NotEnoughCreditsHeaderPremium')" @close="creditNotEnough = false"/>
  <div v-if="isModalOpen" class="modal-overlay">
    <CvExistingLoader />
  </div>
  <div
    class="container"
    @dragover="handleDragOver"
    @dragleave="handleDragLeave"
    @drop="handleDrop"
  >
    <div class="card" :class="{ dragging: isDragging }">
      <div class="icon-container">
        <svg class="icon" viewBox="0 0 24 24">
          <path d="M0 0h24v24H0V0z" fill="none" />
          <path
            d="M19.35 10.04A7.49 7.49 0 0 0 12 4C9.11 4 6.6 5.64 5.35 8.04A5.994 5.994 0 0 0 0 14c0 3.31 2.69 6 6 6h13c2.76 0 5-2.24 5-5 0-2.64-2.05-4.78-4.65-4.96zM19 18H6c-2.21 0-4-1.79-4-4 0-2.05 1.53-3.76 3.56-3.97l1.07-.11.5-.95A5.469 5.469 0 0 1 12 6c2.62 0 4.88 1.86 5.39 4.43l.3 1.5 1.53.11A2.98 2.98 0 0 1 22 15c0 1.65-1.35 3-3 3zM8 13h2.55v3h2.9v-3H16l-4-4z"
          />
        </svg>
      </div>
      <div class="text large">{{ t("cvEvaluation.DragAndDrop") }}</div>
      <div class="text small">{{ t("cvEvaluation.Or") }}</div>
      <button class="button-row" @click="clickUpload">{{ t("cvEvaluation.ImportCV") }}</button>
      <input ref="fileInput" type="file" style="display: none" accept=".pdf" @change="handleFileUpload" />
    </div>
  </div>
</template>

<style scoped>

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1050;
}

.container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40vh;
    margin-bottom: 100px;
    margin-top: 100px;
}
.card {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    padding: 20px;
    width: 646px;
    height: 360px;
    background-color: #ffffff;
    border-radius: 18px;
    border: 2px dashed #505050;
    box-sizing: border-box;
}
.card.dragging {
  background-color: #f0f0f0;
  border-color: #5438da;
}
.icon-container {
    display: flex;
    justify-content: center;
    align-items: center;
}
.icon {
    fill: #909090;
    width: 57px;
    height: 58px;
}
.text {
    color: #909090;
    text-align: center;
}
.large {
    font-size: 24px;
    font-family: 'Montserrat';
    font-weight: 400;
    line-height: 31px;
}
.small {
    font-size: 18px;
    font-family: 'Montserrat';
    font-weight: 400;
    line-height: 23px;
}
.button-row {
    cursor: pointer;
    width: 154px;
    height: 56px;
    padding: 0px 8px;
    border: 2px solid #5438da;
    background-color: transparent;
    color: #5438da;
    font-size: 16px;
    font-family: 'Montserrat';
    font-weight: 400;
    line-height: 19px;
    border-radius: 8px;
    outline: none;
}
.framed-text {
  padding: 10px 20px;
  border: 2px solid #5438da;
  border-radius: 5px;
  margin-top: 10px;
  text-align: center;
  display: inline-block;
}
</style>
