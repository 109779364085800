<script setup lang="ts">
import { ref } from "vue";

import { useI18n } from "vue-i18n";

import type { SectionScore } from "@/composables/types";
import { useEvaluationUtils } from "@/utils/getEvaluationScore";
import { getActualScore } from "@/utils/scoreAdjustments";

const { getScoreColor, getScoreRange, scoreOutOfHundred } = useEvaluationUtils();
const isHovered = ref(false);
const { locale } = useI18n();

defineProps<{
    scoreDetails: SectionScore | undefined
  }>();

const scoreToDashArray = (score: string): string => {
  const percentage = parseInt(score);

  const circumference = 283;
  const filledLength = (percentage / 100) * circumference;
  return `${filledLength} ${circumference}`;
};

</script>

<template>
  <div :class="`cv-evaluation-section-scores`">
    <div class="score-card" @mouseenter="isHovered = true"
         @mouseleave="isHovered = false">
      <svg class="score-circle" viewBox="0 0 100 100">
        <circle class="circle-background" cx="50" cy="50" r="45"></circle>
        <circle
          class="circle-score"
          :stroke="getScoreColor(scoreOutOfHundred(scoreDetails) ? scoreDetails?.score || '' : getActualScore(Number(scoreDetails?.score), scoreDetails?.sectionName, locale) || '')"
          cx="50"
          cy="50"
          r="45"
          :stroke-dasharray="scoreToDashArray(scoreOutOfHundred(scoreDetails) ? scoreDetails?.score || '' : getActualScore(Number(scoreDetails?.score), scoreDetails?.sectionName, locale) || '')"
          transform="rotate(-90 50 50)"
        ></circle>
        <text class="score-text" x="50" y="55" dominant-baseline="middle" text-anchor="middle">
          {{ scoreOutOfHundred(scoreDetails) ? scoreDetails?.score || '' : getActualScore(Number(scoreDetails?.score), scoreDetails?.sectionName, locale) || '' }}%
        </text>
      </svg>
      <div class="score-range">{{ getScoreRange(scoreOutOfHundred(scoreDetails) ? scoreDetails?.score || '' : getActualScore(Number(scoreDetails?.score), scoreDetails?.sectionName, locale) || '') }} <svg class="icon-class"  viewBox="0 0 24 24">
        <path d="M0 0h24v24H0V0z" fill="none">
        </path>
        <path d="M11 7h2v2h-2V7zm0 4h2v6h-2v-6zm1-9C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z">
        </path>
      </svg></div>
      <div v-if="isHovered" class="exp-text-div">
        <span>{{ scoreDetails?.evaluation }}</span>

      </div>
    </div>
  </div>
</template>

  <style scoped>

  .icon-class{
    display: flex;
    color: #909090;
    fill: #909090;
    font-size: 24px;
    width: 24px;
    height: 24px;
  }

  .score-range{
    min-width: 200px;
    display: flex;
    align-items: center;
    gap: 5px;
  }

.exp-text-div{
    position: absolute;
    display: flex;
    z-index: 1;
    width: 300px;
    background-color: #aa93f3;
    padding: 20px;

}

.cv-evaluation-section-scores {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 30%;
}

.cv-evaluation-section-scores.center-items {
  justify-content: center;
}

.score-card {
  width: calc(33.33% - 20px);
  text-align: center;
  margin: 10px;
}

.cv-evaluation-section-scores.center-items .score-card {
  width: calc(50% - 20px);
}

  .score-circle {
    width: 100px;
    height: 100px;
  }

  .circle-background {
    fill: none;
    stroke: #f2f2f2;
    stroke-width: 10;
  }

  .circle-score {
  fill: none;
  stroke-width: 10;
  stroke-linecap: round;
  transition: none;
}

  .score-text {
    font-size: 24px;
    fill: #333;
  }

  .score-range {
    margin-top: 10px;
  }

  .score-range.Poor {
    color: #f21d0d;
  }

  .score-range.Fair {
    color: #ff4f17;
  }

  .score-range.Average {
    color: #ffc107;
  }

  .score-range.Good {
    color: #67bd6a;
  }

  .score-range.Excellent {
    color: #429846;
  }
  .cv-evaluation-section-scores.center-items {
  justify-content: center;
}
.section-name {
  font-weight: bold;
  margin-bottom: 5px;
  text-align: center;
}

  </style>
