<script setup lang="ts">
import { onMounted, ref, defineProps, defineEmits, watchEffect, defineExpose } from "vue";

import Quill from "quill";

const props = defineProps({
  htmlValue: String,
  enhanced: Boolean,
});
const emit = defineEmits(["updateHtmlValue", "openModal"]);
const editor = ref<HTMLElement | null>(null);
const quill = ref<Quill | null>(null);

onMounted(() => {
  if (editor.value) {
    quill.value = new Quill(editor.value, {
      theme: "snow",
    });

    if (props.htmlValue) {
      quill.value.root.innerHTML = props.htmlValue;
    }

    const quillEditorContent = editor.value.querySelector(".ql-editor");
    if (quillEditorContent) {
      quillEditorContent.addEventListener("blur", () => {

        const htmlContent = quill.value?.root.innerHTML;
        emit("updateHtmlValue", htmlContent);
      }, true);
    }
  }
});

const updateInnerHtml = () => {

  if (props.htmlValue !== undefined && quill.value) {
    quill.value.root.innerHTML = props.htmlValue;
  }
};

defineExpose({
  updateInnerHtml,
});

watchEffect(() => {
  if (props.enhanced && quill.value) {
    editor.value?.querySelectorAll(".ql-editor p").forEach((p) => {
      (p as HTMLElement).style.backgroundColor = "#ff6666";
    });
    editor.value?.querySelectorAll(".ql-editor ul").forEach((p) => {
      (p as HTMLElement).style.backgroundColor = "#ff6666";
    });
    editor.value?.querySelectorAll(".ql-editor li").forEach((p) => {
      (p as HTMLElement).style.backgroundColor = "#ff6666";
    });
  } else {
    editor.value?.querySelectorAll(".ql-editor p").forEach((p) => {
      (p as HTMLElement).style.backgroundColor = "";
    });
    editor.value?.querySelectorAll(".ql-editor ul").forEach((p) => {
      (p as HTMLElement).style.backgroundColor = "";
    });
    editor.value?.querySelectorAll(".ql-editor li").forEach((p) => {
      (p as HTMLElement).style.backgroundColor = "";
    });
  }
});

const openModal = () => {
  if(props.enhanced) {emit("openModal");}
};
</script>

<template>
  <div ref="editor" @click.stop="openModal"></div>
</template>

<style scoped>
.ql-editor {
  height: 200px;
  overflow-y: auto;
  background-color: #f0f0f0;
}
</style>