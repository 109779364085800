import { getCurrentLocale } from "../utils/locale";

export const constructLangPrefix = () => {
  const localeValue = getCurrentLocale();
  const langPrefix = localeValue === "en" ? "" : `/${localeValue}`;
  return langPrefix;
};

export const constructLocalizedPath = (path: string) => {
  const langPrefix = constructLangPrefix();
  return `${langPrefix}${path}`;
};