<script setup lang="ts">
import { onMounted } from "vue";

import { useI18n } from "vue-i18n";

import FaqView from "../views/faq-view/FaqView.vue";

const { t } = useI18n();

onMounted(() => {
  // Add meta tags for SEO
  document.title = t("faqs.metaTitle");

  const metaDescription = document.createElement("meta");
  metaDescription.name = "description";
  metaDescription.content = t("faqs.metaDescription");
  document.head.appendChild(metaDescription);

  const metaKeywords = document.createElement("meta");
  metaKeywords.name = "keywords";
  metaKeywords.content = t("faqs.metaKeywords");
  document.head.appendChild(metaKeywords);

  // Add JSON-LD structured data for FAQs
  const faqStructuredData = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": [
      {
        "@type": "Question",
        "name": t("faqs.serviceOffered"),
        "acceptedAnswer": {
          "@type": "Answer",
          "text": t("faqs.serviceOfferedAnswer"),
        },
      },
      {
        "@type": "Question",
        "name": t("faqs.howToUpgrade"),
        "acceptedAnswer": {
          "@type": "Answer",
          "text": t("faqs.howToUpgradeAnswer"),
        },
      },
      {
        "@type": "Question",
        "name": t("faqs.paymentMethods"),
        "acceptedAnswer": {
          "@type": "Answer",
          "text": t("faqs.paymentMethodsAnswer"),
        },
      },
      {
        "@type": "Question",
        "name": t("faqs.cancelSubscription"),
        "acceptedAnswer": {
          "@type": "Answer",
          "text": t("faqs.cancelSubscriptionAnswer"),
        },
      },
      {
        "@type": "Question",
        "name": t("faqs.premiumTemplates"),
        "acceptedAnswer": {
          "@type": "Answer",
          "text": t("faqs.premiumTemplatesAnswer"),
        },
      },
      {
        "@type": "Question",
        "name": t("faqs.accountToUse"),
        "acceptedAnswer": {
          "@type": "Answer",
          "text": t("faqs.accountToUseAnswer"),
        },
      },
      {
        "@type": "Question",
        "name": t("faqs.techSupport"),
        "acceptedAnswer": {
          "@type": "Answer",
          "text": t("faqs.techSupportAnswer"),
        },
      },
      {
        "@type": "Question",
        "name": t("faqs.useMultipleDevices"),
        "acceptedAnswer": {
          "@type": "Answer",
          "text": t("faqs.useMultipleDevicesAnswer"),
        },
      },
      {
        "@type": "Question",
        "name": t("faqs.dataPrivacy"),
        "acceptedAnswer": {
          "@type": "Answer",
          "text": t("faqs.dataPrivacyAnswer"),
        },
      },
      {
        "@type": "Question",
        "name": t("faqs.editAfterCreate"),
        "acceptedAnswer": {
          "@type": "Answer",
          "text": t("faqs.editAfterCreateAnswer"),
        },
      },
      {
        "@type": "Question",
        "name": t("faqs.downloadResume"),
        "acceptedAnswer": {
          "@type": "Answer",
          "text": t("faqs.downloadResumeAnswer"),
        },
      },
    ],
  };

  const script = document.createElement("script");
  script.type = "application/ld+json";
  script.textContent = JSON.stringify(faqStructuredData);
  document.head.appendChild(script);
});
</script>

<template>
  <FaqView />
</template>
