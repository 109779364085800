import axios from "axios";
import type {
  UserCredential } from "firebase/auth";
import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  GoogleAuthProvider,
  FacebookAuthProvider,
  OAuthProvider,
  signInWithPopup,
  sendEmailVerification,
  sendPasswordResetEmail,
  // deleteUser,
} from "firebase/auth";

import { API_URL, BASE_URL } from "@/config";
import router from "@/router";
import { getCurrentLocale } from "@/utils/locale";

const auth = getAuth();

export const forgotPassword = async (email: string, locale: string) => {
  auth.languageCode = locale;

  try {
    const langPrefix = locale === "en" ? "" : `/${locale}`;
    await sendPasswordResetEmail(auth, email, {
      url: `${BASE_URL}${langPrefix}/reset-password`,
      handleCodeInApp: true,
    });
  } catch (error) {
    console.error("Error in forgotPassword function:", error);
    throw error;
  }
};

export const sendVerificationEmail = async (userCredential: UserCredential, url: string, locale: string) => {
  auth.languageCode = locale;
  await sendEmailVerification(userCredential.user, {
    url,
    handleCodeInApp: true,
  });
};

const getCSRFToken = async (): Promise<string> => {
  const response = await axios.get(API_URL + "/csrf-token", { withCredentials: true });
  return response.data.csrfToken;
};

const getJWT = async (idToken: string): Promise<string> => {
  const csrfToken = await getCSRFToken();
  const response = await axios.post(
    API_URL + "/login",
    { idToken },
    {
      headers: {
        "X-CSRF-Token": csrfToken,
      },
      withCredentials: true,
    },
  );
  return response.data.token;
};

export const signUp = async (email: string, password: string) => {
  const userCredential = await createUserWithEmailAndPassword(auth, email, password);

  sendVerificationEmail(userCredential, `${BASE_URL}/verify-account?uid=${userCredential.user.uid}`, getCurrentLocale());
  const idToken = await userCredential.user.getIdToken();
  const jwt = await getJWT(idToken);
  localStorage.setItem("jwt", jwt);
  return userCredential;
};

export const signIn = async (email: string, password: string) => {
  const userCredential = await signInWithEmailAndPassword(auth, email, password);
  const user = userCredential.user;

  if (!user.emailVerified) {
    return { userCredential, emailVerified: false };
  }
  const idToken = await userCredential.user.getIdToken();
  const jwt = await getJWT(idToken);
  localStorage.setItem("jwt", jwt);
  if (router.currentRoute.value.query.sessionExpired === "true") {
    router.replace({ query: { ...router.currentRoute.value.query, sessionExpired: undefined } });
  }
  return { userCredential, emailVerified: true };
};

export const signOutUser = async (sessionExpired = false) => {

  await signOut(auth);
  localStorage.removeItem("jwt");
  if(sessionExpired){
    router.push({ path: "/", query: { sessionExpired: "true" } });
  }
  else{
    router.push({ path: "/" });
  }
};

export const signInWithGoogle = async () => {
  const provider = new GoogleAuthProvider();
  const userCredential = await signInWithPopup(auth, provider);
  const idToken = await userCredential.user.getIdToken();
  const jwt = await getJWT(idToken);
  localStorage.setItem("jwt", jwt);
  if (router.currentRoute.value.query.sessionExpired === "true") {
    router.replace({ query: { ...router.currentRoute.value.query, sessionExpired: undefined } });
  }
  return userCredential;
};

export const signInWithFacebook = async () => {
  const provider = new FacebookAuthProvider();
  const userCredential = await signInWithPopup(auth, provider);
  const idToken = await userCredential.user.getIdToken();
  const jwt = await getJWT(idToken);
  localStorage.setItem("jwt", jwt);
  if (router.currentRoute.value.query.sessionExpired === "true") {
    router.replace({ query: { ...router.currentRoute.value.query, sessionExpired: undefined } });
  }
  return userCredential;
};

export const signInWithLinkedIn = async () => {
  const provider = new OAuthProvider("linkedin.com");
  const userCredential = await signInWithPopup(auth, provider);
  const idToken = await userCredential.user.getIdToken();
  const jwt = await getJWT(idToken);
  localStorage.setItem("jwt", jwt);
  if (router.currentRoute.value.query.sessionExpired === "true") {
    router.replace({ query: { ...router.currentRoute.value.query, sessionExpired: undefined } });
  }
  return userCredential;
};

export const deleteUserAccount = async () => {
  const user = auth.currentUser;
  if (!user) {
    throw new Error("No user is currently signed in.");
  }

  try {
    // await deleteUser(user);
    await signOut(auth);
    localStorage.removeItem("jwt");
  } catch (error) {
    console.error("Error deleting user account:", error);
    throw error;
  }
};
