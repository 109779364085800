<script setup lang="ts">
import { onMounted, ref, watch, onUnmounted, nextTick } from "vue";

import { useI18n } from "vue-i18n";

import type { CvData } from "@/composables/types";
import type { HTMLElementWithVNode, ForbiddenZone } from "@/views/cv-creation-view/const";
import { adjustLayout } from "@/views/cv-creation-view/utils/adjustPreview";
import { containsTextOutsideHtml } from "@/views/cv-creation-view/utils/text";

const { cvData, adjust = false } = defineProps<{
  cvData: CvData,
  adjust?: boolean
}>();

const { t } = useI18n();

const divManipulatorRef = ref<HTMLElementWithVNode | null>(null);
const forbiddenZones = ref<ForbiddenZone[]>([]);

const updateForbiddenZones = () => {
  const children = divManipulatorRef.value ? Array.from(divManipulatorRef.value.children) as HTMLElement[] : [];
  if (children.length > 0) {
    const rectForFirst = children[0].getBoundingClientRect();

    forbiddenZones.value = [
      { start: rectForFirst.y + 740, end: rectForFirst.y + 840 },
      { start: rectForFirst.y + 1590, end: rectForFirst.y + 1680 },
      { start: rectForFirst.y + 2350, end: rectForFirst.y + 2490 },
      { start: rectForFirst.y + 3150, end: rectForFirst.y + 3290 },
    ];
  }
};

const updateLeftSectionHeight = () => {
  const leftSection = divManipulatorRef.value?.querySelector(".left-section") as HTMLElement | null;
  if (leftSection) {
    const contentHeight = leftSection.scrollHeight;
    const height196vh = window.innerHeight * 1.96;
    const height392vh = window.innerHeight * 3.92;
    const height588vh = window.innerHeight * 5.88;
    const height784vh = window.innerHeight * 7.84;

    if (contentHeight <= height196vh) {
      leftSection.style.minHeight = "196vh";
    } else if (contentHeight > height196vh && contentHeight <= height392vh) {
      leftSection.style.minHeight = "392vh";
    } else if (contentHeight > height392vh && contentHeight <= height588vh) {
      leftSection.style.minHeight = "588vh";
    } else if (contentHeight > height588vh && contentHeight <= height784vh) {
      leftSection.style.minHeight = "784vh";
    } else {
      leftSection.style.minHeight = `${contentHeight}px`;
    }
  }
};
const isColorDark = (color: string): boolean => {
  let r, g, b;
  if (color.startsWith("#")) {
    const hex = color.slice(1);
    r = parseInt(hex.substring(0, 2), 16);
    g = parseInt(hex.substring(2, 4), 16);
    b = parseInt(hex.substring(4, 6), 16);
  } else {
    const rgb = color.match(/\d+/g);
    [r, g, b] = rgb ? rgb.map(Number) : [0, 0, 0];
  }
  const brightness = (r * 299 + g * 587 + b * 114) / 1000;
  return brightness < 128;
};

const setTextColorBasedOnBg = () => {
  const leftSection = divManipulatorRef.value?.querySelector(".left-section") as HTMLElement | null;
  if (leftSection) {
    const backgroundColor = getComputedStyle(leftSection).backgroundColor;
    const textColor = isColorDark(backgroundColor) ? "white" : "black";

    const setTextColorRecursive = (element: HTMLElement) => {
      element.style.color = textColor;

      if (element.classList.contains("custom-line-left")) {
        element.style.borderBottomColor = textColor;
      }

      Array.from(element.children).forEach((child) => {
        setTextColorRecursive(child as HTMLElement);
      });
    };

    setTextColorRecursive(leftSection);
  }
};

watch(() => cvData, () => {
  setTextColorBasedOnBg();
  updateForbiddenZones();
  if(!adjust)
  {
    updateLeftSectionHeight();
    adjustLayout(forbiddenZones.value, divManipulatorRef);
  }
}, { deep: true, immediate: true });

watch(cvData, (newValue) => {
  const fontSize = `${newValue.resumeFontSize}px`;
  document.documentElement.style.setProperty("--global-font-size", fontSize);
  setTextColorBasedOnBg();
});

onMounted(async () => {
  await nextTick();
  setTextColorBasedOnBg();
  updateForbiddenZones();
  if(!adjust)
  {
    updateLeftSectionHeight();
    adjustLayout(forbiddenZones.value, divManipulatorRef);
  }

  const fontSize = `${cvData.resumeFontSize}px`;
  document.documentElement.style.setProperty("--global-font-size", fontSize);
  window.addEventListener("resize", () => {
    updateForbiddenZones();
    updateLeftSectionHeight();
    if(!adjust)
    {
      updateLeftSectionHeight();
      adjustLayout(forbiddenZones.value, divManipulatorRef);
    }
  });
});

onUnmounted(() => {
  setTextColorBasedOnBg();
  window.removeEventListener("resize", () => {
    updateForbiddenZones();
    updateLeftSectionHeight();
    if(!adjust)
    {
      updateLeftSectionHeight();
      adjustLayout(forbiddenZones.value, divManipulatorRef);
    }
  });
});

</script>

<template>
  <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0-beta3/css/all.min.css">
  <div ref="divManipulatorRef" class="div-manipulator" :style="{ '--resume-color': cvData.resumeColor, fontFamily: cvData.resumeFontFamily }">
    <section class="resume">
      <div class="left-section" :style="{backgroundColor: cvData.resumeColor}">
        <div class="contact-basics-div">
          <div class="fullname-text-contact">
            {{ cvData.contact?.firstName }} {{ cvData.contact?.lastName }}
          </div>
          <div v-if="cvData.contact?.jobTitle" class="name-text-contact">
            <i class="fas fa-briefcase"></i> {{ cvData.contact?.jobTitle }}
          </div>
          <div v-if="cvData.contact?.phone" class="name-text-contact">
            <i class="fas fa-phone"></i> {{ cvData.contact?.phone }}
          </div>
          <div v-if="cvData.contact?.email" class="name-text-contact">
            <i class="fas fa-envelope"></i> {{ cvData.contact?.email }}
          </div>
          <div v-if="cvData.contact?.address" class="name-text-contact">
            <i class="fas fa-map-marker-alt"></i> {{ cvData.contact?.address }}
          </div>
          <div v-if="cvData.contact?.city" class="name-text-contact">
            <i class="fas fa-city"></i> {{ cvData.contact?.city }}
          </div>
          <div v-if="cvData.contact?.country" class="name-text-contact">
            <i class="fas fa-flag"></i> {{ cvData.contact?.country }}
          </div>
          <div v-if="cvData.contact?.birthDate" class="name-text-contact">
            <i class="fas fa-birthday-cake"></i> {{ cvData.contact?.birthDate }}
          </div>
        </div>
        <div v-if="cvData.skills && cvData.skills.length > 0" class="skills">
          <div class="section-title-text">{{ t('Skills') }}</div>
          <div class="custom-line-left"></div>
          <div v-for="skill in cvData.skills" :key="skill.id" style="padding-bottom: 5px;">
            <div class="organization-div-side">
              <div class="skill-name-div">
                <div class="organization-text">{{ skill?.name }}</div>
              </div>
              <div class="skill-level-div">
                <div v-if="skill.level && cvData.showSkillLevels" class="organization-text">{{ skill?.level }}</div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="cvData.languages && cvData.languages.length > 0" class="languages">
          <div class="section-title-text">{{ t('Languages') }}</div>
          <div class="custom-line-left"></div>
          <div v-for="language in cvData.languages" :key="language.id" style="padding-bottom: 5px;">
            <div class="organization-div-side">
              <div class="skill-name-div">
                <div class="organization-text">{{ language.name }}</div>
              </div>
              <div class="skill-level-div">
                <div v-if="language.level && cvData.showLanguageLevels" class="organization-text">{{ language?.level }}</div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="cvData.hobbies && cvData.hobbies.length > 0" class="hobbies">
          <div class="section-title-text">{{ t('Hobbies') }}</div>
          <div class="custom-line-left"></div>
          <div v-for="hobby in cvData.hobbies" :key="hobby.id" style="padding-bottom: 7px;">
            <div class="description-text" v-html="hobby?.description"></div>
          </div>
        </div>
        <div v-if="cvData.honors && cvData.honors.length > 0" class="honors">
          <div class="section-title-text">{{ t('Honors & Awards') }}</div>
          <div class="custom-line-left"></div>
          <div v-for="honor in cvData.honors" :key="honor.id" style="padding-bottom: 8px;">
            <div class="description-text" v-html="honor?.description"></div>
          </div>
        </div>
        <div v-if="cvData.publications && cvData.publications.length > 0" class="publications">
          <div class="section-title-text">{{ t('Publications') }}</div>
          <div class="custom-line-left"></div>
          <div v-for="publication in cvData.publications" :key="publication.id" style="padding-bottom: 8px;">
            <div class="description-text" v-html="publication?.description"></div>
          </div>
        </div>
        <div v-if="cvData.references && cvData.references.length > 0" class="references" style="padding-bottom: 8px;">
          <div class="section-title-text">{{ t('References') }}</div>
          <div class="custom-line-left"></div>
          <div>
            <div v-for="reference in cvData.references" :key="reference.id" style="padding-bottom: 10px;">
              <div v-if="reference?.name" class="organization-text" style="padding-bottom: 5px;">{{ reference?.name }} </div>
              <div v-if="reference?.company" class="organization-text" style="padding-bottom: 5px;">{{ reference?.company }}</div>
              <div v-if="reference?.phone" class="organization-text" style="padding-bottom: 5px;">{{ reference?.phone }}</div>
              <div v-if="reference?.email" class="organization-text" style="padding-bottom: 5px;">{{ reference?.email }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="right-section">
        <div class="main-section">
          <div v-if="cvData.contact?.description" class="introduction-div">
            <div class="section-title">
              <div class="section-title-text">{{ t('cvCreationView.Summary') }}</div>
            </div>
            <div class="custom-line"> </div>
            <div class="introduction-text" v-html="cvData.contact?.description"></div>
          </div>
        </div>
        <div v-if="cvData.educations && cvData.educations.length > 0" class="main-section">
          <div class="section-title">
            <div class="section-title-text">{{ t('Education') }}</div>
            <div class="custom-line"></div>
          </div>
          <div v-for="education in cvData.educations" :key="education.id" class="section-elements">
            <div class="inline-container">
              <div class="title-text">{{ education.school }}{{ education.location ? ', ' + education.location : '' }}</div>
            </div>
            <div class="organization-div-side">
              <div class="organization-text">
                <span v-if="education.degree">{{ education.degree }}</span>
                <span v-if="education.startDate">,&nbsp;{{ education.startDate }}</span>
                <span v-if="education.startDate && education.endDate">&nbsp;-&nbsp;</span>
                <span v-if="!education.startDate && education.endDate">,&nbsp;</span>
                <span v-if="education.endDate">{{ education.endDate }}</span>
              </div>
            </div>
            <div v-if="education.description && containsTextOutsideHtml(education.description)" class="description-div">
              <div class="description-text" v-html="education.description"></div>
            </div>
          </div>
        </div>
        <div v-if="cvData.experiences && cvData.experiences.length > 0" class="main-section">
          <div class="section-title">
            <div class="section-title-text">{{ t('Experience') }}</div>
            <div class="custom-line"> </div>
          </div>
          <div v-for="experience in cvData.experiences" :key="experience.id" class="section-elements">
            <div class="organization-div-side">
              <div v-if="experience.title" class="title-text">{{ experience.title }}</div>
              <div v-if="experience?.startDate" class="title-text">,&nbsp;{{ experience?.startDate }}</div>
              <div v-if="experience?.startDate && experience?.endDate" class="title-text">&nbsp;-&nbsp;</div>
              <span v-if="!experience.startDate && experience.endDate">,&nbsp;</span>
              <div v-if="experience?.endDate" class="title-text">{{ experience?.endDate }}</div>
            </div>
            <div class="organization-div-side">
              <div class="organization-text">{{ experience.company }}{{ experience.location ? ', ' + experience.location : '' }}</div>
            </div>
            <div v-if="experience.description && containsTextOutsideHtml(experience.description)" class="description-div">
              <div class="description-text" v-html="experience.description"></div>
            </div>
          </div>
        </div>
        <div v-if="cvData.projects && cvData.projects.length > 0" class="main-section">
          <div class="section-title">
            <div class="section-title-text">{{ t('Projects') }}</div>
            <div class="custom-line"></div>
          </div>
          <div v-for="project in cvData.projects" :key="project.id" class="section-elements">
            <div class="organization-div-side">
              <div v-if="project.title" class="title-text">{{ project.title }}</div>
              <div v-if="project?.startDate" class="title-text">,&nbsp;{{ project?.startDate }}</div>
              <div v-if="project?.startDate ?? project?.endDate" class="title-text">&nbsp;-&nbsp;</div>
              <div v-if="project?.endDate" class="title-text">{{ project?.endDate }}</div>
            </div>
            <div v-if="project.description && containsTextOutsideHtml(project.description)" class="description-div">
              <div class="description-text" v-html="project.description"></div>
            </div>
          </div>
        </div>
        <div v-if="cvData.certifications?.length" class="main-section">
          <div class="section-title">
            <div class="section-title-text">{{ t('Certifications & Courses') }}</div>
            <div class="custom-line"></div>
          </div>
          <div v-for="certification in cvData.certifications" :key="certification.id" class="section-elements-side">
            <div v-if="certification.course" class="title-text">{{ certification.course }} &nbsp; &nbsp; ({{ certification.institute }})</div>
            <div v-if="certification?.startDate" class="title-text">,&nbsp; {{ certification?.startDate }}</div>
            <div v-if="certification?.startDate && certification?.endDate" class="title-text"> &nbsp;-&nbsp;</div>
            <div v-if="certification?.endDate" class="title-text">{{ certification?.endDate }}</div>
          </div>
        </div>
        <div v-if="cvData.exhibitions && cvData.exhibitions.length > 0" class="publications">
          <div class="section-title-text">{{ t('Exhibitions') }}</div>
          <div class="custom-line"></div>
          <div v-for="exhibition in cvData.exhibitions" :key="exhibition.id" style="padding-bottom: 8px;">
            <div class="description-text" v-html="exhibition?.description"></div>
          </div>
        </div>
        <div v-if="cvData.voluntaries && cvData.voluntaries.length > 0" class="publications">
          <div class="section-title-text">{{ t('Voluntary Activities') }}</div>
          <div class="custom-line"></div>
          <div v-for="voluntary in cvData.voluntaries" :key="voluntary.id" style="padding-bottom: 8px;">
            <div class="description-text" v-html="voluntary?.description"></div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<style scoped>

:root {
  --global-font-size: 10px;
}

.skill-name-div{
width: 47%;
margin-right: 10px;
}

.skill-level-div{
  width: 45%;
  padding-right: 3%;
}

.resume {
  width: 595px;
  max-width: 100%;
  background: var(--white);
  display: flex;
  text-align: start !important;
  min-height: 700px;
}

.languages, .hobbies, .honors, .publications, .references, .skills {
  margin-top: 10px;
  font-size: var(--global-font-size);
  font-weight: 800;
  padding-bottom: 10px;
  background-color: inherit;
}

.organization-div-side {
  display: flex;
  align-items: flex-start;
}
.organization-text {
  margin-right: 5px;
}
.left-section {
  width: 35%;
  background-color: var(--resume-color);
  box-sizing: border-box;
  padding-left: 15px;
  text-align: left;
  word-wrap: break-word;
}
.contact-basics-div {
  padding-top: 30px;
  text-align: center;
}
.name-text-contact, .fullname-text-contact {
  font-weight: 700;
  font-size: var(--global-font-size);
  line-height: 27.24px;
  text-align: left;
  padding-left: 5px;
}
.fullname-text-contact {
  font-size: calc(var(--global-font-size) + 8px);
  text-align: center;
}

.right-section {
  width: 65%;
  padding: 30px;
  box-sizing: border-box;
  padding-left: 15px;
}
.h3-sections, .h3-sections-multiple {
  margin-bottom: 10px;
  padding-bottom: 10px;
}
.h3-sections-multiple {
  flex-direction: column;
}

.introduction-div {
  margin-bottom: 5px;
}

.introduction-text {
  font-size: var(--global-font-size);
  line-height: 1.5;
  color: #333;
}

.introduction-text *, .description-text *{
  margin-top: 0px;
  margin-bottom: 0px;
  padding-bottom: 0px;
  padding-top: 0px;
}

.custom-line {
  border-bottom: 2px solid #ccc;
  margin-top: 5px;
  margin-bottom: 5px;
  width: 350px;
  box-sizing: border-box;
}
.custom-line-left {
  border-bottom: 2px solid #ccc;
  margin: 10px 0;
  width: 170px;
  box-sizing: border-box;
}

.main-section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-bottom: 10px;
}

.section-title{
  padding-bottom: 5px;
  margin-bottom: 0px;
}
.section-title-text {
  font-size: calc(var(--global-font-size) + 6px);
  font-weight: 800;
}
.title-text {
  font-size: var(--global-font-size);
  font-weight: 800;
}
.section-elements {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 8px;
}

.section-elements-side {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding-right: 1px;
  margin-bottom: 8px;
}

.organization-text, .period-text {
  font-size: var(--global-font-size);
  color: #333333;
  font-weight: 800;
}
.description-text {
  font-size: var(--global-font-size);
  font-weight: 400;
}
</style>
