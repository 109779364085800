import { getDocument } from "pdfjs-dist";

export const checkFileValidty = (file: File, error1: string, error2: string) => {
  if (file.type !== "application/pdf") {
    alert(error1);
    return false;
  }

  const maxSize = 5 * 1024 * 1024;
  if (file.size > maxSize) {
    alert(error2);
    return false;
  }

  return true;
};

export const processFile = async (file: File, error1: string, error2: string): Promise<string> => {
  if (!checkFileValidty(file, error1, error2)) {
    return "";
  }

  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();

    fileReader.onload = async (ev) => {
      try {
        const typedArray = new Uint8Array(ev?.target?.result as ArrayBuffer);
        const loadingTask = getDocument({ data: typedArray });
        const pdf = await loadingTask.promise;
        let textContent = "";

        for (let pageNum = 1; pageNum <= pdf.numPages; pageNum++) {
          const page = await pdf.getPage(pageNum);
          const text = await page.getTextContent();
          text.items.forEach((item) => {
            if ("str" in item) {
              textContent += item.str + " ";
            }
          });
        }

        resolve(textContent);
      } catch (error) {
        console.error("Error extracting text from PDF:", error);
        reject(error);
      }
    };

    fileReader.onerror = (error) => {
      console.error("Error reading file:", error);
      reject(error);
    };

    fileReader.readAsArrayBuffer(file);
  });
};